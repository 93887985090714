export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Scalar for BigDecimal */
  BigDecimal: any;
  /** Scalar for BigInteger */
  BigInteger: any;
  ClaimId: any;
  /** Scalar for Date */
  Date: any;
  /** Scalar for DateTime */
  DateTime: any;
  DeviceId: any;
  /**
   * Global location number, used world wide to identify legal, physical and
   * functional locations. These are used as identifiers e.g. companies and power
   * meter ids.
   */
  Gln: any;
  IntermediaryId: any;
  JSON: any;
  MeterId: any;
  /** Nothing */
  Void: any;
};

export type Activation = {
  status: ActivationStatus;
  updatedAt: Scalars['Date'];
};

export enum ActivationStatus {
  ACTIVATED = 'ACTIVATED',
  DEACTIVATED = 'DEACTIVATED'
}

export type ActiveEnergyImportedRecord = {
  startTime: Scalars['Date'];
  wh: Scalars['Int'];
};

export type ActiveEnergyImportedRecordInput = {
  startTime: Scalars['Date'];
  wh: Scalars['Int'];
};

export type AddBrightNotificationChannelToAlertSettingInput = {
  alertSettingId: Scalars['ID'];
};

export type AddBrightNotificationChanneltToAlertSettingPayload = {
  notificationChannel: BrightNotificationChannel;
};

export type AddChargingLocationInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  name: Scalars['String'];
};

export type AddChargingLocationPayload = AddChargingLocationSuccessPayload | ChargingLocationAlreadyExistsPayload;

export enum AddChargingLocationResponseType {
  ALREADY_EXISTS = 'ALREADY_EXISTS',
  SUCCESS = 'SUCCESS'
}

export type AddChargingLocationSuccessPayload = {
  chargingLocation: ChargingLocation;
  type: AddChargingLocationResponseType;
};

export type AddChargingPermissionInput = {
  userIdentifier: UserIdentifierInput;
};

export type AddChargingPermissionPayload = {
  modifiedPermission: Permission;
  success: Scalars['Boolean'];
  user: UserInfo;
};

export type AddControllableForLocationInput = {
  controllableId: Scalars['String'];
  locationId: Scalars['String'];
};

export type AddControllableForLocationPayload = {
  location: Location;
};

export type AddDevicePermissionsInput = {
  deviceIdentifiers: Array<DeviceIdentifierInput>;
  userIdentifier: UserIdentifierInput;
};

export type AddDevicePermissionsPayload = {
  modifiedPermissions: Array<Permission>;
  success: Scalars['Boolean'];
  user: UserInfo;
};

export type AddEstimatedHourlyActiveEnergyLimitWarningAlertSettingInput = {
  deviceId: Scalars['String'];
  limitInWattHours: Scalars['Int'];
};

export type AddFjuttError = {
  message?: Maybe<Scalars['String']>;
  type?: Maybe<AddFjuttErrorType>;
};

export enum AddFjuttErrorType {
  FJUTT_ALREADY_REGISTERED = 'FJUTT_ALREADY_REGISTERED',
  FJUTT_NOT_FOUND = 'FJUTT_NOT_FOUND',
  UNKNOWN = 'UNKNOWN'
}

export type AddFjuttResponse = {
  error?: Maybe<AddFjuttError>;
  fjutt?: Maybe<Fjutt>;
  harkUserToken?: Maybe<HarkUserToken>;
};

export type AddHourlyConsumptionLimitEstimationWarningAlertSettingInput = {
  deviceId: Scalars['String'];
  limitInWatts: Scalars['Int'];
};

export type AddIntermediaryApiKeyInput = {
  /** The name of the api key. This is to easier keep track of keys. */
  name: Scalars['String'];
};

export type AddIntermediaryWebhookNotificationChannelToAlertSettingInput = {
  alertSettingId: Scalars['ID'];
  callbackUrl: Scalars['String'];
  intermediaryId?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['JSON']>;
};

export type AddIntermediaryWebhookNotificationChannelToAlertSettingPayload = {
  notificationChannel: IntermediaryWebhookNotificationChannel;
};

export type AddLocationInput = {
  coordinates: LatLngInput;
  name: Scalars['String'];
};

export type AddLocationPayload = {
  location: Location;
};

export type AddPriceWarningAlertSettingInput = {
  energyBiddingZone: EnergyBiddingZone;
};

export type AddPushNotificationChannelToAlertSettingInput = {
  alertSettingId: Scalars['ID'];
};

export type AddPushNotificationChannelToAlertSettingPayload = {
  notificationChannel: PushNotificationChannel;
};

export type AddSustainableConsumptionAlertSettingInput = {
  deviceId: Scalars['String'];
  limitInWattHours: Scalars['Int'];
};

export type AddUserInput = {
  userIdentifier: UserIdentifierInput;
};

export type AddUserPayload = {
  success: Scalars['Boolean'];
  user: UserInfo;
};

export type AddUserSessionInput = {
  userIdentifier: UserIdentifierInput;
};

export type AddUserSessionPayload = {
  session: SessionInfo;
  success: Scalars['Boolean'];
  user: UserInfo;
};

export type AddressInput = {
  address?: InputMaybe<Scalars['String']>;
  countryCode: CountryCode;
  postalCode: Scalars['String'];
};

export type AksepterInvitasjonInput = {
  invitasjonId: Scalars['ID'];
  token: Scalars['String'];
};

export type AkseptertBruker = {
  /** ISO-8601 */
  akseptert?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  navn?: Maybe<Scalars['String']>;
};

export type AkseptertInvitasjon = {
  invitasjonId: Scalars['ID'];
  orgId?: Maybe<Scalars['ID']>;
  orgnummer: Scalars['ID'];
  token: Scalars['String'];
};

export enum AlertChannelType {
  BRIGHT_NOTIFICATION = 'BRIGHT_NOTIFICATION',
  INTERMEDIARY_WEBHOOK = 'INTERMEDIARY_WEBHOOK',
  PUSH_NOTIFICATION = 'PUSH_NOTIFICATION'
}

/** Types of alert events. */
export type AlertEvent = EstimatedConsumptionWarningEvent | HourlyConsumptionReachedEvent | HourlyConsumptionSustainableEvent | HourlyConsumptionWarningEvent | PriceWarningEvent | SustainableConsumptionEvent;

export enum AlertEventType {
  ESTIMATED_HOURLY_ACTIVE_ENERGY_LIMIT_WARNING = 'ESTIMATED_HOURLY_ACTIVE_ENERGY_LIMIT_WARNING',
  HOURLY_CONSUMPTION_LIMIT_ESTIMATION_WARNING = 'HOURLY_CONSUMPTION_LIMIT_ESTIMATION_WARNING',
  HOURLY_CONSUMPTION_REACHED = 'HOURLY_CONSUMPTION_REACHED',
  PRICE_WARNING = 'PRICE_WARNING',
  SUSTAINABLE_CONSUMPTION = 'SUSTAINABLE_CONSUMPTION',
  UNSUSTAINABLE_CONSUMPTION = 'UNSUSTAINABLE_CONSUMPTION'
}

export type AlertEventsOfChannelTypeInput = {
  deviceId?: InputMaybe<Scalars['String']>;
  notificationChannelType: NotificationChannelType;
};

export type AlertEventsOfChannelTypePayload = {
  events: Array<AlertEvent>;
};

export type AlertEventsPayload = {
  events: Array<AlertEvent>;
};

/** An alert setting is a configuration that defines what type, when, or how an alert should be triggered. */
export type AlertSetting = EstimatedHourlyActiveEnergyLimitWarningAlertSetting | HourlyConsumptionLimitEstimationWarningAlertSetting | PriceWarningAlertSetting | SustainableConsumptionAlertSetting;

export type AlertSettingCreator = EndUserAlertSettingCreator | IntermediaryAlertSettingCreator;

export enum AlertSettingCreatorType {
  END_USER = 'END_USER',
  INTERMEDIARY = 'INTERMEDIARY'
}

export enum AlertType {
  ESTIMATED_HOURLY_ACTIVE_ENERGY_LIMIT_WARNING = 'ESTIMATED_HOURLY_ACTIVE_ENERGY_LIMIT_WARNING',
  HOURLY_CONSUMPTION_LIMIT_ESTIMATION_WARNING = 'HOURLY_CONSUMPTION_LIMIT_ESTIMATION_WARNING',
  PRICE_WARNING = 'PRICE_WARNING',
  SUSTAINABLE_CONSUMPTION_ALERT = 'SUSTAINABLE_CONSUMPTION_ALERT'
}

export type Anlegg = {
  aarsforbruk?: Maybe<Scalars['BigInteger']>;
  adresse?: Maybe<Scalars['String']>;
  anleggsstatus?: Maybe<Anleggsstatus>;
  egenAnleggsbeskrivelse?: Maybe<Scalars['String']>;
  fakturamerke?: Maybe<Scalars['String']>;
  gjennomfakturert?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  leveringspunkt?: Maybe<Scalars['String']>;
  leveringspunktID?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['Float']>;
  /** Ofte nummeret som står på utsiden av måleren */
  maalernummer?: Maybe<Scalars['String']>;
  /** Elhubs virtuelle id, unik for hele verden */
  maalerpunktId?: Maybe<Scalars['ID']>;
  naeringskode?: Maybe<Scalars['String']>;
  naeringskodeID?: Maybe<Scalars['String']>;
  netteier?: Maybe<Scalars['String']>;
  omregningsfaktor?: Maybe<Scalars['Float']>;
  /** ISO-8601 */
  omregningsfaktorFradato?: Maybe<Scalars['Date']>;
  postnummer?: Maybe<Scalars['String']>;
  poststed?: Maybe<Scalars['String']>;
  prisOmrade?: Maybe<Scalars['String']>;
  salgsordrer?: Maybe<Array<Maybe<Salgsordre>>>;
  tittel?: Maybe<Scalars['String']>;
};

export type Anleggsinnmelding2Input = {
  fakturamerke?: InputMaybe<Scalars['String']>;
  malepunktId?: InputMaybe<Scalars['String']>;
  /** ISO-8601 */
  oppstartsdato?: InputMaybe<Scalars['Date']>;
  orgId?: InputMaybe<Scalars['String']>;
  tariffId?: InputMaybe<Scalars['String']>;
};

export type AnleggsinnmeldingInput = {
  anlegg: InnmeldtAnleggInput;
  fakturamerke: Scalars['String'];
  fakturamottaker?: InputMaybe<Scalars['String']>;
  fakturering?: InputMaybe<Fakturering>;
  /** ISO-8601 */
  oppstartsdato?: InputMaybe<Scalars['Date']>;
  tariffId: Scalars['String'];
};

export enum Anleggsstatus {
  AKTIV = 'AKTIV',
  OPPHOERT = 'OPPHOERT',
  PENDING = 'PENDING',
  UNDER_OPPHOER = 'UNDER_OPPHOER',
  UNDER_OVERTAKELSE = 'UNDER_OVERTAKELSE'
}

export type AnleggsutmeldingInput = {
  maalepunktId: Scalars['String'];
  merknad?: InputMaybe<Scalars['String']>;
  nyKundeId?: InputMaybe<Scalars['String']>;
  nyKundeNavn?: InputMaybe<Scalars['String']>;
  /** ISO-8601 */
  utmeldingsdato: Scalars['Date'];
};

export enum Avlesning {
  Automatisk = 'Automatisk',
  IkkeAvlest = 'IkkeAvlest',
  Manuell = 'Manuell',
  Ukjent = 'Ukjent'
}

export enum Avregning {
  IkkeOppgjort = 'IkkeOppgjort',
  IkkeProfilmaalt = 'IkkeProfilmaalt',
  Profilmaalt = 'Profilmaalt',
  Ukjent = 'Ukjent'
}

export type AvslaaInvitasjon = {
  aarsak?: Maybe<Scalars['String']>;
  invitasjonId: Scalars['ID'];
  token: Scalars['String'];
};

export type AvslaaInvitasjonInput = {
  aarsak?: InputMaybe<Scalars['String']>;
  invitasjonId: Scalars['ID'];
  token: Scalars['String'];
};

export type BatchInnmeldingAnleggInput = {
  innmeldinger?: InputMaybe<Array<InputMaybe<InnmeldingInput>>>;
  kunde?: InputMaybe<KundeInput>;
};

export type BatchInnmeldingStatus = {
  innmeldingStatuser?: Maybe<Array<Maybe<InnmeldingStatus>>>;
};

export type Bedrift = {
  anlegg?: Maybe<Array<Maybe<Anlegg>>>;
  antallForfalteFakturaer?: Maybe<Scalars['BigInteger']>;
  brukere?: Maybe<Array<Maybe<Bruker>>>;
  dokumenter?: Maybe<CompanyDocuments>;
  faktura?: Maybe<Faktura>;
  fakturaer?: Maybe<Fakturaside>;
  fjutter?: Maybe<Array<Maybe<Fjutt>>>;
  forbruk?: Maybe<Forbruk>;
  forbruksdetaljer?: Maybe<Forbruksdetaljer>;
  godkjenteTariffer?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  invitasjoner?: Maybe<Array<Maybe<Invitasjon>>>;
  kontakt?: Maybe<Scalars['String']>;
  kontaktEpost?: Maybe<Scalars['String']>;
  kontaktinformasjon?: Maybe<Kontaktinformasjon>;
  moduler?: Maybe<Array<Maybe<Modul>>>;
  navn?: Maybe<Scalars['String']>;
  orgnummer?: Maybe<Scalars['String']>;
  totalSaldoUtestaaende?: Maybe<Scalars['Float']>;
};


export type BedriftAnleggArgs = {
  inkluderAvviklede?: InputMaybe<Scalars['Boolean']>;
};


export type BedriftFakturaArgs = {
  guid?: InputMaybe<Scalars['String']>;
};


export type BedriftFakturaerArgs = {
  antall?: InputMaybe<Scalars['Int']>;
  fakturamerker?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  filter?: InputMaybe<FakturaFilter>;
  fraDato?: InputMaybe<Scalars['Date']>;
  maalere?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  naeringskoder?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  side?: InputMaybe<Scalars['Int']>;
  tilDato?: InputMaybe<Scalars['Date']>;
};


export type BedriftForbrukArgs = {
  fakturamerker?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fraDato?: InputMaybe<Scalars['Date']>;
  maalere?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  naeringskoder?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  opploesning?: InputMaybe<Opploesning>;
  tilDato?: InputMaybe<Scalars['Date']>;
};


export type BedriftForbruksdetaljerArgs = {
  fakturamerker?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fraDato?: InputMaybe<Scalars['Date']>;
  maalere?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  naeringskoder?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  opploesning?: InputMaybe<Opploesning>;
  retning?: InputMaybe<Retning>;
  tilDato?: InputMaybe<Scalars['Date']>;
};


export type BedriftInvitasjonerArgs = {
  filter?: InputMaybe<Tilstand>;
};

export type BedriftsTariff = {
  beskrivelse?: Maybe<Scalars['String']>;
  tariffId?: Maybe<Scalars['String']>;
};

export type Bedriftsoppslag = {
  kilde?: Maybe<BedriftsoppslagKilde>;
  navn?: Maybe<Scalars['String']>;
  orgnummer?: Maybe<Scalars['String']>;
};

export enum BedriftsoppslagKilde {
  Bedriftsportalen = 'Bedriftsportalen',
  Bisnode = 'Bisnode',
  UtilityCloud = 'UtilityCloud'
}

export type BrightNotificationChannel = {
  forUserWithId: Scalars['String'];
  type: NotificationChannelType;
};

export type Bruker = {
  epost?: Maybe<Scalars['String']>;
  etternavn?: Maybe<Scalars['String']>;
  fornavn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  moduler?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Brukerprofil = {
  epost?: Maybe<Scalars['String']>;
  etternavn?: Maybe<Scalars['String']>;
  fornavn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  innstillinger?: Maybe<Array<Maybe<Innstilling>>>;
  maalergrupperinger?: Maybe<Array<Maybe<Maalergruppering>>>;
  moduler?: Maybe<Array<Maybe<Scalars['String']>>>;
  validerteEposter?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BulkinvitasjonInput = {
  /** Navn på moduler */
  moduler?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  mottakere: Array<InputMaybe<InvitasjonsmottakerInput>>;
};

export type Bulkstatus = {
  melding?: Maybe<Scalars['String']>;
  orgnr?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type CancelOrderInput = {
  orderId: Scalars['String'];
};

export type CapacityCost = {
  capacityStep?: Maybe<CapacityStep>;
  cost: PriceSpecification;
};

export enum CapacityModelBusinessType {
  ConstantBusiness = 'ConstantBusiness',
  FuseAndVoltageSizeBusiness = 'FuseAndVoltageSizeBusiness',
  MonthlyPeaksAtDifferentDaysBusiness = 'MonthlyPeaksAtDifferentDaysBusiness',
  WeightedYearlyRollingPeaksInDifferentWeeksBusiness = 'WeightedYearlyRollingPeaksInDifferentWeeksBusiness'
}

export enum CapacityModelType {
  FuseAndHomeBasedSelectableSeasonal = 'FuseAndHomeBasedSelectableSeasonal',
  FuseAndHomeType = 'FuseAndHomeType',
  FuseAndVoltageSize = 'FuseAndVoltageSize',
  MonthlyPeaksAtDifferentDays = 'MonthlyPeaksAtDifferentDays',
  MonthlyPeaksAtDifferentDaysLinearWithBase = 'MonthlyPeaksAtDifferentDaysLinearWithBase',
  WeightedYearlyRollingPeaksInDifferentWeeks = 'WeightedYearlyRollingPeaksInDifferentWeeks'
}

/** The capacity steps includes as range in Wh and a price for the capacity step */
export type CapacityStep = {
  capacityStepPrice: PriceSpecification;
  rangeInWh: EnergyRangeInWh;
};

export type ChargeCost = {
  cost: Scalars['Float'];
  currency?: Maybe<Currency>;
  savings: Scalars['Float'];
};

/**
 * Type ChargeSettings
 * - `id` is the id of the charge settings.
 * - `vehicleId` is the id of the vehicle.
 * - `departureTimes` is a list of departure times for each day of the week.
 * - `desiredBatteryLevel` is the battery level at which the car should be charged at the departure time.
 * - `directChargingBatteryLevel` is the battery level at which the car should start charging immediately.
 * - `enableSmartChargingAt` is the date at which smart charging should be enabled.
 * - `isSmartChargingEnabled` is whether smart charging is enabled.
 * - `isSolarChargingEnabled` is whether solar charging is enabled.
 * - `maxAllowedTargetSoC` is the maximum allowed target state of charge.
 * - `timeZone` is the time zone of the user.
 */
export type ChargeSettings = {
  defaultStartBatteryLevel: Scalars['Float'];
  departureTimes: DepartureTimes;
  desiredBatteryLevel: Scalars['Float'];
  directChargingBatteryLevel: Scalars['Float'];
  enableSmartChargingAt?: Maybe<Scalars['Date']>;
  id: Scalars['String'];
  isSmartChargingEnabled: Scalars['Boolean'];
  isSolarChargingEnabled: Scalars['Boolean'];
  maxAllowedTargetSoC: Scalars['Float'];
  timeZone: Timezone;
  vehicleId: Scalars['String'];
};

export type ChargingHistoryEntry = {
  chargedEnergyInKwh: Scalars['Float'];
  endTime: Scalars['Date'];
  startTime: Scalars['Date'];
};

export type ChargingLocation = {
  id: Scalars['ID'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  name: Scalars['String'];
};

export type ChargingLocationAlreadyExistsPayload = {
  type: AddChargingLocationResponseType;
};

export type ChargingSession = {
  chargeCost: ChargeCost;
  chargingLocationId: Scalars['String'];
  energy: Array<Maybe<ChargingHistoryEntry>>;
  isSmartSession: Scalars['Boolean'];
  sessionId: Scalars['String'];
};

export type ChargingStatisticsEntry = {
  costSum: Scalars['Float'];
  date: Scalars['String'];
  estimatedSavings: Scalars['Float'];
  kw: Range;
  kwhSum: Scalars['Float'];
  nonSmartPrice: Range;
  price: Range;
  priceCurrency: Currency;
  smartSession: Scalars['Boolean'];
};

export type ChargingStatisticsInput = {
  chargingLocationId?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  id?: InputMaybe<Scalars['String']>;
  resolution?: InputMaybe<ChargingStatisticsResolution>;
  startDate: Scalars['Date'];
  type?: InputMaybe<ChargingStatisticsType>;
  utcOffset?: InputMaybe<Scalars['Float']>;
};

export type ChargingStatisticsPayload = {
  entries: Array<ChargingStatisticsEntry>;
};

export enum ChargingStatisticsResolution {
  DAY = 'DAY',
  HALF_HOUR = 'HALF_HOUR',
  HOUR = 'HOUR',
  MONTH = 'MONTH',
  WEEK = 'WEEK',
  YEAR = 'YEAR'
}

export enum ChargingStatisticsType {
  CHARGER = 'CHARGER',
  HVAC = 'HVAC',
  VEHICLE = 'VEHICLE'
}

export type CheckJedlixConnectionInput = {
  connectionId: Scalars['String'];
};

export type CommonSignInInput = {
  password: Scalars['String'];
  username: Scalars['String'];
  vendor: CommonSignInVendor;
};

export type CommonSignInPayload = {
  success: Scalars['Boolean'];
};

export enum CommonSignInVendor {
  MILL = 'MILL'
}

export type Company = {
  kundeNavn: Scalars['String'];
  orgNr: Scalars['ID'];
};

export type CompanyDocument = {
  contentType?: Maybe<Scalars['String']>;
  /** ISO-8601 */
  created?: Maybe<Scalars['DateTime']>;
  customTitle?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  read: Scalars['Boolean'];
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type CompanyDocuments = {
  documents?: Maybe<Array<Maybe<CompanyDocument>>>;
  unread: Scalars['Int'];
};

export type CompanyInput = {
  kundeNavn: Scalars['String'];
  orgNr: Scalars['ID'];
};

export type ConfiguredIntegrationVendorDevice = ConfiguredIntegrationVendorVehicle;

export type ConfiguredIntegrationVendorInfo = {
  devices: Array<ConfiguredIntegrationVendorDevice>;
  status: IntegrationVendorStatus;
  vendor: IntegrationVendor;
};

export type ConfiguredIntegrationVendorVehicle = {
  chargeState: ConfiguredIntegrationVendorVehicleChargeState;
  chargingLocationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isReachable: Scalars['Boolean'];
  isSmartChargingCapable: Scalars['Boolean'];
  location: ConfiguredIntegrationVendorVehicleLocationState;
  model: Scalars['String'];
  smartChargingPolicy?: Maybe<SmartChargingPolicy>;
  vendor: IntegrationVendor;
  yearOfProduction: Scalars['Int'];
};

export type ConfiguredIntegrationVendorVehicleChargeState = {
  batteryCapacity: Scalars['Float'];
  batteryLevel: Scalars['Int'];
  chargeRate: Scalars['Float'];
  chargeTimeRemaining: Scalars['Int'];
  isCharging: Scalars['Boolean'];
  isFullyCharged: Scalars['Boolean'];
  isPluggedIn: Scalars['Boolean'];
  range: Scalars['Float'];
};

export type ConfiguredIntegrationVendorVehicleLocationState = GpsDisabledConfiguredIntegrationVendorVehicleLocationState | GpsNeverActivatedConfiguredIntegrationVendorVehicleLocationState | LocationAvailableConfiguredIntegrationVendorVehicleLocationState;

export enum ConfiguredIntegrationVendorVehicleLocationStateType {
  GPS_DISABLED = 'GPS_DISABLED',
  GPS_NEVER_ACTIVATED = 'GPS_NEVER_ACTIVATED',
  LOCATION_AVAILABLE = 'LOCATION_AVAILABLE'
}

export type ConnectExternalUserInput = {
  userIdentifier: UserIdentifierInput;
};

export type ConnectExternalUserPayload = {
  /** Devices shipped to the user. If no devices have been shipped yet, the list is empty. Use the `ordersForUser` query to know the status of the user's orders. */
  devices: Array<DeviceInfo>;
  /** The sessions object contains an `accessToken` to be used by apps to run queries on behalf of the external user. This token will have permissions to get data for the devices shipped to the user. */
  session: SessionInfo;
  success: Scalars['Boolean'];
  user: UserInfo;
};

export type ConsideringSmartChargingStatus = {
  consideration: SmartChargingConsideration;
  externalStart: Scalars['Boolean'];
  type: SmartChargingStatusType;
};

/**
 * Capacity model where grid voltage and/or fuse size make the capacity steps.
 *
 * Capacity model used by Alut, NettiNord, SuNett.
 */
export type ConstantBusiness = {
  price: PriceSpecification;
  type: CapacityModelBusinessType;
  yearlyEnergyFund: PriceSpecification;
};

/** Energy model with same price for all times of the day, week and year */
export type ConstantPrice = {
  pricesAndTaxes: PricesAndTaxes;
  rushPricing?: Maybe<Array<Maybe<RushPricingPeriod>>>;
  type: EnergyModelType;
};

/** Energy model with same price for all times of the day, week and year */
export type ConstantPriceBusiness = {
  pricesAndTaxes: PricesAndTaxesBusiness;
  rushPricing?: Maybe<Array<Maybe<RushPricingPeriod>>>;
  type: EnergyModelBusinessType;
};

export type ConstantPriceDemandTariff = {
  pricePerKw: PriceSpecification;
};

export type ConstantPriceWeightedDemandTariff = {
  monthWeights: MonthWeights;
  pricePerKw: PriceSpecification;
};

export enum ConsumerType {
  BUSINESS = 'BUSINESS',
  PRIVATE = 'PRIVATE'
}

export enum ConsumerTypeEnterprise {
  EnterpriseHighVoltage = 'EnterpriseHighVoltage',
  EnterpriseLowVoltage = 'EnterpriseLowVoltage'
}

export type ContentfulEntry = {
  beskrivelse?: Maybe<Scalars['String']>;
  bildeUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  tittel?: Maybe<Scalars['String']>;
};

export type Controllable = {
  id: Scalars['String'];
  intermediaryId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  ownerId: Scalars['String'];
  type: ControllableType;
  vendor: Vendor;
};

export enum ControllableType {
  ROOM = 'ROOM'
}

export type ControllablesForUserPayload = {
  controllables: Array<Controllable>;
};

export type CostPerHourEntry = {
  cost: PricesAndTaxes;
  hour: Scalars['String'];
};

export enum CountryCode {
  NO = 'NO'
}

/**
 * Input object for creating charge settings.
 * - `defaultStartBatteryLevel` can be set as a default battery level for the beginning of a session, if we cannot get the SoC from the vehicle this value will be used.
 * - `departureTimes` is a list of departure times for each day of the week.
 * - `desiredBatteryLevel` is the battery level at which the car should be charged at the departure time.
 * - `directChargingBatteryLevel` is the battery level at which the car should start charging immediately.
 * - `enableSmartChargingAt` is the date at which smart charging should be enabled.
 * - `isSmartChargingEnabled` is whether smart charging is enabled.
 * - `isSolarChargingEnabled` is whether solar charging is enabled.
 * - `maxAllowedTargetSoC` is the maximum allowed target state of charge.
 * - `timeZone` is the time zone of the user.
 * - `vehicleId` is the id of the vehicle.
 */
export type CreateChargeSettingsInput = {
  defaultStartBatteryLevel?: InputMaybe<Scalars['Float']>;
  departureTimes: DepartureTimesInput;
  desiredBatteryLevel: Scalars['Float'];
  directChargingBatteryLevel: Scalars['Float'];
  enableSmartChargingAt?: InputMaybe<Scalars['Date']>;
  isSmartChargingEnabled: Scalars['Boolean'];
  isSolarChargingEnabled: Scalars['Boolean'];
  maxAllowedTargetSoC: Scalars['Float'];
  timeZone: Timezone;
  vehicleId: Scalars['String'];
};

/** Response object when creating charge settings. */
export type CreateChargeSettingsPayload = {
  chargeSettings: ChargeSettings;
};

export type CreateJedlixChargingLocationInput = {
  address: JedlixAddressInput;
  coordinates: LatLngInput;
};

export type CreateJedlixUserInput = {
  defaultCountry: CountryCode;
  locale: Scalars['String'];
};

export type CreationInfo = {
  at: Scalars['Date'];
  by: UserInfo;
};

export enum Currency {
  NOK = 'NOK',
  SEK = 'SEK'
}

export type CurrentIntermediaryPayload = {
  alertSettings?: Maybe<Array<AlertSetting>>;
};


export type CurrentIntermediaryPayloadAlertSettingsArgs = {
  input?: InputMaybe<IntermediaryAlertSettingsInput>;
};

export type CurrentRangeMonthlyPrice = {
  /** From, exclusive */
  fromCurrent: Scalars['Int'];
  /** Price for this current range */
  monthlyPrice: PriceSpecification;
  /** To, inclusive */
  toCurrent: Scalars['Int'];
};

export type CurrentRangePrice = {
  /** From, exclusive */
  fromCurrent: Scalars['Int'];
  /** To, inclusive */
  toCurrent: Scalars['Int'];
  /** Price for this current range */
  yearlyPrice: PriceSpecification;
};

export type CurrentRangeWithSelectable = {
  fromCurrent: Scalars['Int'];
  selectableEnergyModel: SelectableEnergyModel;
  toCurrent: Scalars['Int'];
};

export type CurrentRangeWithSelectableBusiness = {
  fromCurrent: Scalars['Int'];
  selectableEnergyModel: SelectableEnergyModelBusiness;
  toCurrent: Scalars['Int'];
};

export type CustomError = {
  message: Scalars['String'];
  type: ErrorType;
};

export type DataInput = {
  records: Array<ActiveEnergyImportedRecordInput>;
};

export type DateRange = {
  /** Day of month and month as a number, including this date */
  from: DayAndMonth;
  /** Day of month and month as a number, excluding this date */
  until: DayAndMonth;
};

export type DayAndMonth = {
  day: Scalars['Int'];
  month: Month;
};

/**
 * There may be different prices for day and night, maybe in combination with
 * seasonal (summer/winter) prices. Usually the day is from 6 to 22, but not
 * always.
 */
export type DayAndNightPrices = {
  day: PricesAndTaxesForPartOfDay;
  night: PricesAndTaxesForPartOfDay;
};

/**
 * There may be different prices for day and night, maybe in combination with
 * seasonal (summer/winter) prices. Usually the day is from 6 to 22, but not
 * always.
 */
export type DayAndNightPricesBusiness = {
  day: PricesAndTaxesForPartOfDayBusiness;
  night: PricesAndTaxesForPartOfDayBusiness;
};

export type DeleteAlertSettingInput = {
  id: Scalars['ID'];
};

export type DeleteAlertSettingPayload = {
  success: Scalars['Boolean'];
};

export type DeleteChargingLocationInput = {
  chargingLocationId: Scalars['String'];
};

export type DeleteChargingLocationPayload = {
  success: Scalars['Boolean'];
};

export type DeleteJedlixChargingLocationInput = {
  chargingLocationId: Scalars['String'];
};

export type DeleteJedlixChargingLocationPayload = {
  response?: Maybe<Scalars['Void']>;
};

export type DeleteJedlixUserPayload = {
  response?: Maybe<Scalars['Void']>;
};

export type DeleteJedlixVehicleInput = {
  vehicleId: Scalars['String'];
};

export type DeleteJedlixVehiclePayload = {
  response?: Maybe<Scalars['Void']>;
};

export type DeleteMeInput = {
  accessToken: Scalars['String'];
  userId: Scalars['String'];
};

export type DeleteMePayload = {
  success: Scalars['Boolean'];
  user: UserInfo;
};

export type DeliveryChargeArea = {
  id: DeliveryChargeModelId;
};

export type DeliveryChargeAreaInput = {
  addressInput?: InputMaybe<AddressInput>;
  latLngInput?: InputMaybe<LatLngInput>;
};

export type DeliveryChargeCapacityBusinessModel = ConstantBusiness | FuseAndVoltageSizeBusiness | MonthlyPeaksAtDifferentDaysBusiness | WeightedYearlyRollingPeaksInDifferentWeeksBusiness;

export type DeliveryChargeCapacityModel = FuseAndHomeBasedSelectableSeasonal | FuseAndHomeType | FuseAndVoltageSize | MonthlyPeaksAtDifferentDays | MonthlyPeaksAtDifferentDaysLinearWithBase | WeightedYearlyRollingPeaksInDifferentWeeks;

export type DeliveryChargeCost = {
  capacityCost: CapacityCost;
  energyCost: EnergyCost;
};

export type DeliveryChargeCostBusiness = {
  capacityCost: CapacityCost;
  energyCost: EnergyCost;
};

export type DeliveryChargeCostBusinessInput = {
  dataInput: DataInput;
  deliveryChargeModelId?: InputMaybe<DeliveryChargeModelId>;
  gln?: InputMaybe<Scalars['Gln']>;
};

export type DeliveryChargeCostEnterprise = {
  demandTariffCost: DemandTariffCost;
  energyCost: EnergyCost;
  fixedFeeCost: FixedFeeCost;
};

export type DeliveryChargeCostEnterpriseInput = {
  consumerType: ConsumerTypeEnterprise;
  dataInput: DataInput;
  deliveryChargeModelId?: InputMaybe<DeliveryChargeModelId>;
  gln?: InputMaybe<Scalars['Gln']>;
};

export type DeliveryChargeCostInput = {
  dataInput: DataInput;
  deliveryChargeModelId?: InputMaybe<DeliveryChargeModelId>;
  gln?: InputMaybe<Scalars['Gln']>;
};

export type DeliveryChargeDemandTariffEnterpriseModel = ConstantPriceDemandTariff | ConstantPriceWeightedDemandTariff | SeasonalPricesDemandTariff | SeasonalTieredDemandTariff | TieredDemandTariff;

export type DeliveryChargeEnergyBusinessModel = ConstantPriceBusiness | DemandChargeStepBusiness | DemandChargeStepWithSeasonsBusiness | DifferentPricesDayAndNightBusiness | DifferentPricesSeasonalBusiness | DifferentPricesSeasonalDayAndNightBusiness | FuseBasedSelectableBusiness | SelectableEnergyModelBusiness;

export type DeliveryChargeEnergyEnterpriseModel = ConstantPriceBusiness | DifferentPricesDayAndNightBusiness | DifferentPricesSeasonalBusiness;

export type DeliveryChargeEnergyModel = ConstantPrice | DifferentPricesDayAndNight | DifferentPricesSeasonal | DifferentPricesSeasonalDayAndNight | FuseBasedSelectable | HomeBasedSelectableSeasonal | SelectableEnergyModel;

/** Energy taxes (electrical power tax and energy fund tax) */
export type DeliveryChargeEnergyTaxes = {
  /** Electrical power tax (Avgift på elektrisk kraft, also called "forbruksavgift") */
  electricalPowerTax: PriceSpecification;
  /** Tax to the energy fund (Enova-avgift) */
  energyFund: PriceSpecification;
};

/** Energy taxes for business (electrical power tax) */
export type DeliveryChargeEnergyTaxesBusiness = {
  /** Electrical power tax (Avgift på elektrisk kraft, also called "forbruksavgift") */
  electricalPowerTax: PriceSpecification;
};

export type DeliveryChargeFixedFeeEnterpriseModel = PerMonthFixedFee | PerYearDividedByDaysFixedFee;

/** Return type for delivery charge models of type private */
export type DeliveryChargeModel = {
  capacityModel: DeliveryChargeCapacityModel;
  energyModel: DeliveryChargeEnergyModel;
  gln: Scalars['Gln'];
  id: DeliveryChargeModelId;
  timezone: Timezone;
  validFrom: Scalars['Date'];
};

/** Return type for delivery charge models of type business */
export type DeliveryChargeModelBusiness = {
  capacityModel: DeliveryChargeCapacityBusinessModel;
  energyModel: DeliveryChargeEnergyBusinessModel;
  gln: Scalars['Gln'];
  id: DeliveryChargeModelId;
  timezone: Timezone;
  validFrom: Scalars['Date'];
};

/** Input for delivery charge models of type business using `gln` or our `id` */
export type DeliveryChargeModelBusinessInput = {
  date?: InputMaybe<Scalars['Date']>;
  gln?: InputMaybe<Scalars['Gln']>;
  id?: InputMaybe<DeliveryChargeModelId>;
};

export type DeliveryChargeModelEnterprise = {
  demandTariffModel: DeliveryChargeDemandTariffEnterpriseModel;
  energyModel: DeliveryChargeEnergyEnterpriseModel;
  fixedFeeModel: DeliveryChargeFixedFeeEnterpriseModel;
  gln: Scalars['Gln'];
  id: DeliveryChargeModelId;
  timezone: Timezone;
  validFrom: Scalars['Date'];
};

export enum DeliveryChargeModelId {
  AGDER_ENERGI_NETT = 'AGDER_ENERGI_NETT',
  ALE_EL = 'ALE_EL',
  ALUT = 'ALUT',
  ANDOY_NETT = 'ANDOY_NETT',
  ARVA = 'ARVA',
  ARVIKA_TEKNIK = 'ARVIKA_TEKNIK',
  ASKER_NETT = 'ASKER_NETT',
  AUSTEVOLL_KRAFTLAG = 'AUSTEVOLL_KRAFTLAG',
  BARENTS_NETT = 'BARENTS_NETT',
  BENGTSFORS_ENERGI_NAT = 'BENGTSFORS_ENERGI_NAT',
  BINDAL_KRAFTLAG = 'BINDAL_KRAFTLAG',
  BJARE_KRAFT = 'BJARE_KRAFT',
  BKK_NETT = 'BKK_NETT',
  BLASJON_NAT = 'BLASJON_NAT',
  BOMLO_KRAFTNETT = 'BOMLO_KRAFTNETT',
  BOO_ENERGI = 'BOO_ENERGI',
  BREHEIM_NETT = 'BREHEIM_NETT',
  BRITTEDALS_ELNAT = 'BRITTEDALS_ELNAT',
  DE_NETT = 'DE_NETT',
  ELINETT = 'ELINETT',
  ELLEVIO = 'ELLEVIO',
  ELMEA = 'ELMEA',
  ELVENETT = 'ELVENETT',
  ELVERKET_HOLAND = 'ELVERKET_HOLAND',
  ELVIA = 'ELVIA',
  ENIDA = 'ENIDA',
  ETNA = 'ETNA',
  EVERKET = 'EVERKET',
  FAGNE = 'FAGNE',
  FALU_ELNAT = 'FALU_ELNAT',
  FILIPSTAD_ENERGINAT = 'FILIPSTAD_ENERGINAT',
  FJELLNETT = 'FJELLNETT',
  FOIE = 'FOIE',
  FORE = 'FORE',
  FUSA_KRAFTLAG = 'FUSA_KRAFTLAG',
  GISLAVED_ENERGI = 'GISLAVED_ENERGI',
  GLITRE_ENERGI_NETT = 'GLITRE_ENERGI_NETT',
  GLITRE_NETT = 'GLITRE_NETT',
  GOTLANDS_ENERGI = 'GOTLANDS_ENERGI',
  GRIUG = 'GRIUG',
  HALLINGDAL_KRAFTNETT = 'HALLINGDAL_KRAFTNETT',
  HALLSTAVIKS_ELVERK = 'HALLSTAVIKS_ELVERK',
  HALOGALAND_KRAFT_NETT = 'HALOGALAND_KRAFT_NETT',
  HARJEANS = 'HARJEANS',
  HARRYDA_ENERGI = 'HARRYDA_ENERGI',
  HAVNETT = 'HAVNETT',
  HEMSIL = 'HEMSIL',
  HJARTUMS_ELFORENING = 'HJARTUMS_ELFORENING',
  HJO_ELNAT = 'HJO_ELNAT',
  HOLAND_OG_SETSKOG_ELVERK = 'HOLAND_OG_SETSKOG_ELVERK',
  INDRE_HORDALAND_KRAFTNETT = 'INDRE_HORDALAND_KRAFTNETT',
  ISALTEN_NETT = 'ISALTEN_NETT',
  JAMTKRAFT = 'JAMTKRAFT',
  JAREN_EVERK = 'JAREN_EVERK',
  KARLSBORGS_ELNAT = 'KARLSBORGS_ELNAT',
  KE_NETT = 'KE_NETT',
  KLIVE = 'KLIVE',
  KUNGALV_ENERGI = 'KUNGALV_ENERGI',
  KVAM_ENERGI_NETT = 'KVAM_ENERGI_NETT',
  KYSTNETT = 'KYSTNETT',
  LANDSKRONA_ENERGI = 'LANDSKRONA_ENERGI',
  LEDE = 'LEDE',
  LEGA_NETT = 'LEGA_NETT',
  LERUM = 'LERUM',
  LEVA = 'LEVA',
  LINDE_ENERGI = 'LINDE_ENERGI',
  LINEA = 'LINEA',
  LINJA = 'LINJA',
  LNETT = 'LNETT',
  LUCERNA = 'LUCERNA',
  LUOSTEJOK_NETT = 'LUOSTEJOK_NETT',
  LYSNA = 'LYSNA',
  MELLOM = 'MELLOM',
  MELOY_NETT = 'MELOY_NETT',
  MIDTNETT = 'MIDTNETT',
  MJOLBY_KRAFTNAT = 'MJOLBY_KRAFTNAT',
  MODALEN_KRAFTLAG = 'MODALEN_KRAFTLAG',
  MOLNDAL_ENERGI = 'MOLNDAL_ENERGI',
  MORENETT = 'MORENETT',
  NATKRAFT_BORAS = 'NATKRAFT_BORAS',
  NEAS = 'NEAS',
  NETTINORD = 'NETTINORD',
  NETTSELSKAPET = 'NETTSELSKAPET',
  NJUDUNG_SAVSJO = 'NJUDUNG_SAVSJO',
  NJUDUNG_VETLANDA = 'NJUDUNG_VETLANDA',
  NORDLANDSNETT = 'NORDLANDSNETT',
  NORDVESTNETT = 'NORDVESTNETT',
  NOREFJELL_NETT = 'NOREFJELL_NETT',
  NORGESNETT = 'NORGESNETT',
  NOSSEBRO_ENERGI = 'NOSSEBRO_ENERGI',
  ODDA_ENERGI_NETT = 'ODDA_ENERGI_NETT',
  OLOFSTROMS_KRAFT = 'OLOFSTROMS_KRAFT',
  OLSERODS = 'OLSERODS',
  OVERTORNEA_ENERGI = 'OVERTORNEA_ENERGI',
  OVIK_ENERGI = 'OVIK_ENERGI',
  OXELO_ENERGI = 'OXELO_ENERGI',
  RAKKESTAD_ENERGI = 'RAKKESTAD_ENERGI',
  RAUMA_ENERGI = 'RAUMA_ENERGI',
  RK_NETT = 'RK_NETT',
  ROLLAG_ELVERK_NETT = 'ROLLAG_ELVERK_NETT',
  ROMSDALSNETT = 'ROMSDALSNETT',
  ROROS_EVERK_NETT = 'ROROS_EVERK_NETT',
  SANDHULT_SANDAREDS_ELNAT = 'SANDHULT_SANDAREDS_ELNAT',
  SANDOY_ENERGI = 'SANDOY_ENERGI',
  SANDVIKEN_ENERGI = 'SANDVIKEN_ENERGI',
  SJOGERSTADS = 'SJOGERSTADS',
  SKELLEFTEA_KRAFT = 'SKELLEFTEA_KRAFT',
  SKIAKER_NETT = 'SKIAKER_NETT',
  SKJAK_ENERGI_NETT = 'SKJAK_ENERGI_NETT',
  SKURUPS_ELVERK = 'SKURUPS_ELVERK',
  SODERHAMN = 'SODERHAMN',
  SOR_AURDAL_ENERGI_NETT = 'SOR_AURDAL_ENERGI_NETT',
  STANGE_ENERGI_NETT = 'STANGE_ENERGI_NETT',
  STANNUM = 'STANNUM',
  STRAM = 'STRAM',
  STRAUMEN_NETT = 'STRAUMEN_NETT',
  STRAUMNETT = 'STRAUMNETT',
  SUNETT = 'SUNETT',
  SYGNIR = 'SYGNIR',
  S_NETT = 'S_NETT',
  TELEMARK_NETT = 'TELEMARK_NETT',
  TENDRA_NETT = 'TENDRA_NETT',
  TENSIO_TN = 'TENSIO_TN',
  TENSIO_TS = 'TENSIO_TS',
  TINDRA_NETT = 'TINDRA_NETT',
  TRANAS_ENERGI = 'TRANAS_ENERGI',
  TROLLFJORD_NETT = 'TROLLFJORD_NETT',
  TROLLHATTAN_ENERGI = 'TROLLHATTAN_ENERGI',
  TROMS_KRAFT_NETT = 'TROMS_KRAFT_NETT',
  UDDEVALLA_ENERGI = 'UDDEVALLA_ENERGI',
  ULRICEHAMNS_ENERGI = 'ULRICEHAMNS_ENERGI',
  UMEA_ENERGI = 'UMEA_ENERGI',
  UPPLANDS_ENERGI = 'UPPLANDS_ENERGI',
  UVDAL_KRAFTFORSYNING = 'UVDAL_KRAFTFORSYNING',
  VAGGERYDS_ELVERK = 'VAGGERYDS_ELVERK',
  VANERENERGI = 'VANERENERGI',
  VANG_ENERGI = 'VANG_ENERGI',
  VARBERGSORTENS = 'VARBERGSORTENS',
  VASTERBERGSLAGENS_ELNAT = 'VASTERBERGSLAGENS_ELNAT',
  VATTENFALL = 'VATTENFALL',
  VESTALL = 'VESTALL',
  VESTMAR_NETT = 'VESTMAR_NETT',
  VESTTELEMARK_KRAFTLAG = 'VESTTELEMARK_KRAFTLAG',
  VEVIG = 'VEVIG',
  VISSI = 'VISSI',
  VONETT = 'VONETT'
}

/** Query delivery charge models using `gln` or our `id` */
export type DeliveryChargeModelInput = {
  date?: InputMaybe<Scalars['Date']>;
  gln?: InputMaybe<Scalars['Gln']>;
  id?: InputMaybe<DeliveryChargeModelId>;
};

export type DeliveryChargeTaxesApplicable = {
  electricalPowerTax: Scalars['Boolean'];
  vat: Scalars['Boolean'];
};

export enum DemandChargePeriod {
  Month = 'Month',
  Year = 'Year'
}

export type DemandChargeRange = {
  fromKw: Scalars['Int'];
  untilKw: Scalars['Int'];
};

export type DemandChargeSeason = {
  months: MonthRange;
  pricePerKw: PriceSpecification;
};

export type DemandChargeSeasons = {
  summer: DemandChargeSeason;
  winter: DemandChargeSeason;
};

export type DemandChargeStep = {
  pricePerKw: PriceSpecification;
  rangeInKw: DemandChargeRange;
};

export type DemandChargeStepBusiness = {
  demandChargeAboveStepsPerKw: PriceSpecification;
  demandChargePeriod: DemandChargePeriod;
  demandChargeSteps: Array<DemandChargeStep>;
  energyPricePerKwh: PricesAndTaxesBusiness;
  type: EnergyModelBusinessType;
};

export type DemandChargeStepWithSeasons = {
  rangeInKw: DemandChargeRange;
  seasons: DemandChargeSeasons;
};

export type DemandChargeStepWithSeasonsBusiness = {
  demandChargeAboveSteps: DemandChargeSeasons;
  demandChargePeriod: DemandChargePeriod;
  demandChargeStepsWithSeasons: Array<DemandChargeStepWithSeasons>;
  energyPricePerKwh: PricesAndTaxesBusiness;
  type: EnergyModelBusinessType;
};

export type DemandTariffCost = {
  cost: PriceSpecification;
};

export type DemandTariffTier = {
  powerRangeInKw: PowerRangeInKw;
  pricePerKw: PriceSpecification;
};

/**
 * Type departure times on charge settings.
 * - `departureTimeOverride` is the departure time override for the current day (Optional).
 * - `monday` is the departure time for Monday with format `HH:mm`.
 * - ... etc.
 */
export type DepartureTimes = {
  departureTimeOverride?: Maybe<Scalars['Date']>;
  friday: Scalars['String'];
  monday: Scalars['String'];
  saturday: Scalars['String'];
  sunday: Scalars['String'];
  thursday: Scalars['String'];
  tuesday: Scalars['String'];
  wednesday: Scalars['String'];
};

/**
 * Input object for setting departure times on charge settings.
 * - `departureTimeOverride` is the departure time override for the current day (Optional).
 * - `monday` is the departure time for Monday with format `HH:mm`.
 * - ... etc.
 */
export type DepartureTimesInput = {
  departureTimeOverride?: InputMaybe<Scalars['Date']>;
  friday: Scalars['String'];
  monday: Scalars['String'];
  saturday: Scalars['String'];
  sunday: Scalars['String'];
  thursday: Scalars['String'];
  tuesday: Scalars['String'];
  wednesday: Scalars['String'];
};

export type DeviceFilter = {
  deviceIds?: InputMaybe<Array<Scalars['String']>>;
  recentMeterIds?: InputMaybe<Array<Scalars['String']>>;
};

export type DeviceIdentifierInput = {
  deviceId?: InputMaybe<Scalars['DeviceId']>;
  meterId?: InputMaybe<Scalars['MeterId']>;
};

export type DeviceInfo = {
  activation?: Maybe<Activation>;
  claimId: Scalars['String'];
  claimedAt?: Maybe<Scalars['String']>;
  claimedBy?: Maybe<Scalars['String']>;
  deviceId: Scalars['String'];
  deviceName?: Maybe<Scalars['String']>;
  diagnostic?: Maybe<DiagnosticInfo>;
  intermediaryId?: Maybe<Scalars['String']>;
  metadata?: Maybe<DeviceMetadata>;
  ownedBy?: Maybe<OwnedByUserInfo>;
  permissions: Array<DevicePermission>;
  preferredEnergyBiddingZone?: Maybe<EnergyBiddingZone>;
  recentMeterId?: Maybe<Scalars['MeterId']>;
  returnInfo?: Maybe<ReturnInfo>;
  shippedAt?: Maybe<Scalars['String']>;
  shippedTo?: Maybe<ShippedToUserInfo>;
  subscription?: Maybe<SubscriptionBilling>;
  type: Scalars['String'];
  updateStatus?: Maybe<DeviceUpdateStatus>;
  webhookCallbackUrl?: Maybe<Scalars['String']>;
};

export type DeviceInput = {
  id: Scalars['String'];
  metadata: DeviceMetadataInput;
  type: DeviceType;
};

export type DeviceMetadata = {
  chip?: Maybe<Scalars['String']>;
  espIdfVersionFactoryPartition?: Maybe<Scalars['String']>;
  firmwareTheme?: Maybe<Scalars['String']>;
  firmwareVersionFactoryPartition?: Maybe<Scalars['String']>;
  iccid?: Maybe<Scalars['String']>;
  imei?: Maybe<Scalars['String']>;
  memoryPartitioning?: Maybe<Scalars['String']>;
  modemFirmwareVersion?: Maybe<Scalars['String']>;
  productBatch?: Maybe<Scalars['String']>;
};

export type DeviceMetadataInput = {
  chip: Scalars['String'];
  espIdfVersionFactoryPartition: Scalars['String'];
  firmwareTheme: Scalars['String'];
  firmwareVersionFactoryPartition: Scalars['String'];
  iccid?: InputMaybe<Scalars['String']>;
  imei?: InputMaybe<Scalars['String']>;
  memoryPartitioning: Scalars['String'];
  modemFirmwareVersion?: InputMaybe<Scalars['String']>;
  productBatch: Scalars['String'];
};

/**
 * Input object for updating ownership information
 *
 * Fields will be replaced and missing fields will be removed
 */
export type DeviceOwnerInput = {
  emailAddress?: InputMaybe<Scalars['String']>;
  externalUserId?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  postalAddress?: InputMaybe<OptionalFieldsPostalAddressInput>;
};

export type DevicePermission = {
  permittedEmail: Scalars['String'];
  permittedUserId: Scalars['String'];
};

export type DeviceQueryIdentifier = {
  claimId?: InputMaybe<Scalars['String']>;
  deviceId?: InputMaybe<Scalars['String']>;
  recentMeterId?: InputMaybe<Scalars['String']>;
};

export type DeviceQueryInput = {
  identifier: DeviceQueryIdentifier;
};

export type DeviceQueryPayload = {
  device: DeviceInfo;
};

export type DeviceRefurbishParts = {
  backCasingOk: Scalars['Boolean'];
  boxDeviceLabelOk: Scalars['Boolean'];
  cardboardBoxOk: Scalars['Boolean'];
  deviceLabelOk: Scalars['Boolean'];
  deviceTypeStickerOk: Scalars['Boolean'];
  frontCasingOk: Scalars['Boolean'];
  manualOk: Scalars['Boolean'];
  pcbaOk: Scalars['Boolean'];
  rj45Ok: Scalars['Boolean'];
  sealOk: Scalars['Boolean'];
  usbOk?: Maybe<Scalars['Boolean']>;
};

export type DeviceStats = {
  devicesActivated: Scalars['Int'];
  devicesActivationReady: Scalars['Int'];
  devicesAtCustomer: Scalars['Int'];
  devicesDeactivated: Scalars['Int'];
  returnedDevices: Scalars['Int'];
  total: Scalars['Int'];
};

/** The type of device */
export enum DeviceType {
  HAN_LTEM = 'HAN_LTEM',
  HAN_WIFI = 'HAN_WIFI'
}

export type DeviceUpdateStatus = {
  finishedAt?: Maybe<Scalars['Date']>;
  progressPercent: Scalars['Int'];
  startedAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  version: Scalars['Int'];
};

/** Diagnostic about network state and reboots */
export type DiagnosticInfo = {
  firmwareVersion?: Maybe<Scalars['Int']>;
  hanEnergy?: Maybe<Scalars['Boolean']>;
  lastMessageAt?: Maybe<Scalars['Date']>;
  lastPingAt?: Maybe<Scalars['Date']>;
  publishInterval?: Maybe<Scalars['Float']>;
  reboots?: Maybe<Scalars['Int']>;
  signalStrength?: Maybe<Scalars['Int']>;
};

/**
 * Energy model with different prices for day and night, may also have different prices for
 * weekends and holidays
 */
export type DifferentPricesDayAndNight = {
  holidayPricesFollowNightPrices: Scalars['Boolean'];
  prices: DayAndNightPrices;
  type: EnergyModelType;
  weekendPricesFollowNightPrices: Scalars['Boolean'];
};

/**
 * Has different prices for day and night, may also have different prices for
 * weekends and holidays
 */
export type DifferentPricesDayAndNightBusiness = {
  holidayPricesFollowNightPrices: Scalars['Boolean'];
  prices: DayAndNightPricesBusiness;
  type: EnergyModelBusinessType;
  weekendPricesFollowNightPrices: Scalars['Boolean'];
};

/** Energy model with different prices for summer and winter */
export type DifferentPricesSeasonal = {
  seasons: SeasonalPricesWithoutDayAndNight;
  type: EnergyModelType;
};

/** Has different prices for summer and winter */
export type DifferentPricesSeasonalBusiness = {
  seasons: SeasonalPricesWithoutDayAndNightBusiness;
  type: EnergyModelBusinessType;
};

/**
 * Energy model with different prices for summer and winter, day and night, may also have
 * different prices for weekends and holidays
 */
export type DifferentPricesSeasonalDayAndNight = {
  holidayPricesFollowNightPrices: Scalars['Boolean'];
  seasons: SeasonalPrices;
  type: EnergyModelType;
  weekendPricesFollowNightPrices: Scalars['Boolean'];
};

/**
 * Has different prices for summer and winter, day and night, may also have
 * different prices for weekends and holidays
 */
export type DifferentPricesSeasonalDayAndNightBusiness = {
  holidayPricesFollowNightPrices: Scalars['Boolean'];
  seasons: SeasonalPricesBusiness;
  type: EnergyModelBusinessType;
  weekendPricesFollowNightPrices: Scalars['Boolean'];
};

export type DisabledSmartChargingStatus = {
  externalStart: Scalars['Boolean'];
  type: SmartChargingStatusType;
};

export type DisconnectVendorInput = {
  vendor: IntegrationVendor;
};

export type DisconnectVendorPayload = {
  success: Scalars['Boolean'];
};

export type DistributedDocument = {
  companies?: Maybe<Array<Maybe<Company>>>;
  contentType?: Maybe<Scalars['String']>;
  /** ISO-8601 */
  created?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  customTitle?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** Return type for electrical power taxes */
export type ElectricalPowerTaxes = {
  electricalPowerTax: Scalars['Float'];
  validFrom: Scalars['Date'];
  vatPart: Scalars['Float'];
};

/** Query electrical power taxes */
export type ElectricalPowerTaxesInput = {
  /** Defaults to 'PRIVATE' if not specified */
  consumerType?: InputMaybe<ConsumerType>;
  /** Defaults to 'NO' if not specified */
  countryCode?: InputMaybe<CountryCode>;
  /** Defaults to today if not specified */
  date?: InputMaybe<Scalars['Date']>;
};

export type ElhubAnlegg = {
  avlesning?: Maybe<Avlesning>;
  /** ISO-8601 */
  avlest?: Maybe<Scalars['Date']>;
  avregning?: Maybe<Avregning>;
  blokkert?: Maybe<Scalars['Boolean']>;
  eierId?: Maybe<Scalars['String']>;
  etasje?: Maybe<Scalars['String']>;
  gateadresse?: Maybe<Scalars['String']>;
  husnummer?: Maybe<Scalars['String']>;
  maalepunktId?: Maybe<Scalars['String']>;
  maalernummer?: Maybe<Scalars['String']>;
  nettselskapId?: Maybe<Scalars['String']>;
  postnummer?: Maybe<Scalars['String']>;
  poststed?: Maybe<Scalars['String']>;
};

export type EmailActionInput = {
  email: Scalars['String'];
  redirectUri?: InputMaybe<Scalars['String']>;
};

export type EmailIdentity = IdentityBase & {
  email: Scalars['String'];
  type: IdentityType;
};

export type EmailIdentityInput = {
  email: Scalars['String'];
};

export type EndUserAlertSettingCreator = {
  type: AlertSettingCreatorType;
  userId: Scalars['ID'];
};

export type EndreFaktureringsmaateInput = {
  alleAnlegg: Scalars['Boolean'];
  fakturering: Fakturering;
  melding?: InputMaybe<Scalars['String']>;
  orgId: Scalars['String'];
};

export type Energy = {
  unit: EnergyUnit;
  value: Scalars['Float'];
};

/** Energy bidding zones, as reported by the _European Network of Transmission System Operators for Electricity_ (ENTSO-E). */
export enum EnergyBiddingZone {
  /** Norway: south east */
  NO1 = 'NO1',
  /** Norway: south */
  NO2 = 'NO2',
  /** Norway: mid */
  NO3 = 'NO3',
  /** Norway: north */
  NO4 = 'NO4',
  /** Norway: west */
  NO5 = 'NO5',
  UNRECOGNIZED = 'UNRECOGNIZED'
}

export type EnergyCost = {
  cost: PricesAndTaxes;
  costPerHour?: Maybe<Array<CostPerHourEntry>>;
};

/** Return type for energy fund taxes */
export type EnergyFundTaxes = {
  energyFundTax: Scalars['Float'];
  validFrom: Scalars['Date'];
  vatPart: Scalars['Float'];
  yearlyEnergyFundTax: Scalars['Float'];
};

/** Query energy fund taxes */
export type EnergyFundTaxesInput = {
  /** Defaults to 'PRIVATE' if not specified */
  consumerType?: InputMaybe<ConsumerType>;
  /** Defaults to 'NO' if not specified */
  countryCode?: InputMaybe<CountryCode>;
  /** Defaults to today if not specified */
  date?: InputMaybe<Scalars['Date']>;
};

export enum EnergyModelBusinessType {
  ConstantPriceBusiness = 'ConstantPriceBusiness',
  DemandChargeStepBusiness = 'DemandChargeStepBusiness',
  DemandChargeStepWithSeasonsBusiness = 'DemandChargeStepWithSeasonsBusiness',
  DifferentPricesDayAndNightBusiness = 'DifferentPricesDayAndNightBusiness',
  DifferentPricesSeasonalBusiness = 'DifferentPricesSeasonalBusiness',
  DifferentPricesSeasonalDayAndNightBusiness = 'DifferentPricesSeasonalDayAndNightBusiness',
  FuseBasedSelectableBusiness = 'FuseBasedSelectableBusiness',
  SelectableEnergyModelBusiness = 'SelectableEnergyModelBusiness'
}

export type EnergyModelSeasonalConstant = {
  highDemand: PricesForPartOfYearWithoutDayAndNight;
  highDemandHoursForWorkDays?: Maybe<HourRange>;
  lowDemand: PricesForPartOfYearWithoutDayAndNight;
};

export enum EnergyModelType {
  ConstantPrice = 'ConstantPrice',
  DifferentPricesDayAndNight = 'DifferentPricesDayAndNight',
  DifferentPricesSeasonal = 'DifferentPricesSeasonal',
  DifferentPricesSeasonalDayAndNight = 'DifferentPricesSeasonalDayAndNight',
  FuseBasedSelectable = 'FuseBasedSelectable',
  HomeBasedSelectableSeasonal = 'HomeBasedSelectableSeasonal',
  SelectableEnergyModel = 'SelectableEnergyModel'
}

export type EnergyPriceOfHour = {
  from?: Maybe<Scalars['Date']>;
  priceSpecification?: Maybe<PriceSpecification>;
  to?: Maybe<Scalars['Date']>;
};

/**
 * Energy range is inclusive the from and to fields. A normal range is from 0 to
 * 1999 Wh. The granularity of our data is in Wh.
 */
export type EnergyRangeInWh = {
  /** From, inclusive */
  from: Scalars['Int'];
  /** To, inclusive */
  to: Scalars['Int'];
};

export enum EnergyUnit {
  WATT_HOUR = 'WATT_HOUR'
}

export type Error = UserNotAuthenticatedError | UserNotPermittedError;

export enum ErrorType {
  UserNotAuthenticatedError = 'UserNotAuthenticatedError',
  UserNotPermittedError = 'UserNotPermittedError'
}

/** An event type describes the EstimatedHourlyActiveEnergyLimitWarningAlertSetting event. */
export type EstimatedConsumptionWarningEvent = {
  alertSettingId: Scalars['String'];
  alertType: SentAlertAlertType;
  at: Scalars['Date'];
  deviceId: Scalars['String'];
  energyUsed?: Maybe<Scalars['Float']>;
  intermediaryId?: Maybe<Scalars['String']>;
  limitInWattHours: Scalars['Float'];
  notificationChannelType: NotificationChannelType;
  type: AlertEventType;
  userId?: Maybe<Scalars['String']>;
};

/** An alert that continuously evaluates whether the device has crossed a set limit for energy consumption within the current hour. */
export type EstimatedHourlyActiveEnergyLimitWarningAlertSetting = {
  alertType: AlertType;
  createdBy: AlertSettingCreator;
  deviceId: Scalars['String'];
  id: Scalars['ID'];
  limitInWattHours: Scalars['Int'];
  notificationChannels: Array<NotificationChannel>;
};

export type ExternalUserIdentifier = {
  externalId: Scalars['String'];
};

export type ExternalUserIdentifierInput = {
  externalId: Scalars['String'];
};

export type ExternalUserIdentity = IdentityBase & {
  externalId: Scalars['String'];
  intermediaryId: Scalars['String'];
  type: IdentityType;
};

export type Faktura = {
  beloepInklMoms?: Maybe<Scalars['Float']>;
  detaljer?: Maybe<Fakturadetaljer>;
  erpid?: Maybe<Scalars['String']>;
  /** ISO-8601 */
  fakturadato?: Maybe<Scalars['Date']>;
  fakturanummer?: Maybe<Scalars['String']>;
  fakturastatus?: Maybe<InvoiceStatus>;
  fakturatype?: Maybe<InvoiceType>;
  forbruk?: Maybe<Scalars['Float']>;
  /** ISO-8601 */
  forfallsdato?: Maybe<Scalars['Date']>;
  guid?: Maybe<Scalars['ID']>;
  kundeid?: Maybe<Scalars['String']>;
  linjer?: Maybe<Array<Maybe<Linje>>>;
  maalerpunktIder?: Maybe<Array<Maybe<Scalars['String']>>>;
  orgnummer?: Maybe<Scalars['String']>;
  pdf?: Maybe<Scalars['String']>;
  pdfUrl?: Maybe<Scalars['String']>;
  saldo?: Maybe<Scalars['Float']>;
  source?: Maybe<Source>;
  url?: Maybe<Scalars['String']>;
};

export enum FakturaFilter {
  all = 'all',
  overdue = 'overdue',
  unpaid = 'unpaid'
}

export type Fakturadetaljer = {
  belopEksMoms?: Maybe<Scalars['Float']>;
  belopMoms?: Maybe<Scalars['Float']>;
  debtCaseId?: Maybe<Scalars['String']>;
  debtCaseLink?: Maybe<Scalars['String']>;
  debtCaseSent?: Maybe<Scalars['String']>;
  directDebit?: Maybe<Scalars['String']>;
  distribusjonsDato?: Maybe<Scalars['String']>;
  distribusjonsKanal?: Maybe<Scalars['String']>;
  distribusjonsLogg?: Maybe<Array<Maybe<Scalars['String']>>>;
  erKreditert?: Maybe<Scalars['Boolean']>;
  erpCustomerId?: Maybe<Scalars['String']>;
  erpIdCanceled?: Maybe<Scalars['String']>;
  /** ISO-8601 */
  fakturadato?: Maybe<Scalars['Date']>;
  fakturanummer?: Maybe<Scalars['String']>;
  fakturastatus?: Maybe<InvoiceStatus>;
  fakturatype?: Maybe<InvoiceType>;
  /** ISO-8601 */
  forfallsdato?: Maybe<Scalars['Date']>;
  invoiceLines?: Maybe<Array<Maybe<UcInvoiceLine>>>;
  invoiceNumberCanceled?: Maybe<Scalars['String']>;
  journalEntryId?: Maybe<Scalars['String']>;
  kid?: Maybe<Scalars['String']>;
  /** ISO-8601 */
  originalForfallsdato?: Maybe<Scalars['Date']>;
  paymentAgreementStatus?: Maybe<UcInvoicePaymentAgreementStatus>;
  payments?: Maybe<Array<Maybe<UcInvoicePayment>>>;
  postPeriode?: Maybe<Scalars['String']>;
  saldo?: Maybe<Scalars['Float']>;
  source?: Maybe<Source>;
};

export type Fakturaside = {
  antallSider?: Maybe<Scalars['Int']>;
  fakturaer?: Maybe<Array<Maybe<Faktura>>>;
};

export enum Fakturering {
  ehf = 'ehf',
  epost = 'epost',
  papir = 'papir'
}

export type FixedFeeCost = {
  cost: PricesAndTaxes;
};

export type Fjutt = {
  anleggsId?: Maybe<Scalars['String']>;
  deviceId?: Maybe<Scalars['String']>;
  omregningsfaktor?: Maybe<Scalars['Float']>;
};

export type Forbruk = {
  opploesning?: Maybe<Opploesning>;
  verdier?: Maybe<Array<Maybe<Forbruksverdi>>>;
};

export type Forbruksdetaljer = {
  opploesning?: Maybe<Opploesning>;
  retning?: Maybe<Retning>;
  verdier?: Maybe<Array<Maybe<ForbruksdetaljerVerdi>>>;
};

export type ForbruksdetaljerVerdi = {
  eksportert?: Maybe<Scalars['Float']>;
  /** ISO-8601 */
  fraTid?: Maybe<Scalars['DateTime']>;
  importert?: Maybe<Scalars['Float']>;
  mengde?: Maybe<Scalars['Float']>;
  /** ISO-8601 */
  tilTid?: Maybe<Scalars['DateTime']>;
};

export type Forbruksvarsel = {
  anleggsId?: Maybe<Scalars['String']>;
  beskrivelse: Scalars['String'];
  deviceId: Scalars['String'];
  epost: Scalars['String'];
  grenseIWatt: Scalars['Int'];
  id?: Maybe<Scalars['ID']>;
  orgId: Scalars['String'];
};

export type ForbruksvarselInput = {
  anleggsId?: InputMaybe<Scalars['String']>;
  beskrivelse: Scalars['String'];
  deviceId?: InputMaybe<Scalars['String']>;
  epost: Scalars['String'];
  grenseIWatt: Scalars['Int'];
  id?: InputMaybe<Scalars['ID']>;
  orgId: Scalars['String'];
};

export type Forbruksverdi = {
  /** ISO-8601 */
  fraTid?: Maybe<Scalars['DateTime']>;
  kostnad?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
  /** ISO-8601 */
  tilTid?: Maybe<Scalars['DateTime']>;
};

export type FullyChargedSmartChargingStatus = {
  externalStart: Scalars['Boolean'];
  type: SmartChargingStatusType;
};

/**
 * Capacity model were users can select whether they want a seasonal or constant price model (in combination with the energy model)
 *
 * Capacity model used in some Swedish regions.
 */
export type FuseAndHomeBasedSelectableSeasonal = {
  /** Prices for apartments */
  apartments: MonthlyPriceSpecification;
  /** Prices for houses */
  houses: FuseBasedSelectableSeasonal;
  type: CapacityModelType;
  /** Yearly transmission fees */
  yearlyEnergyFund: PriceSpecification;
};

/**
 * Capacity model where fuse size and type of home make the capacity steps.
 *
 * Capacity model used in some Swedish regions.
 */
export type FuseAndHomeType = {
  /** Prices for apartments */
  apartments: FuseAndHomeTypePrices;
  /** Prices for houses */
  houses: FuseAndHomeTypePrices;
  type: CapacityModelType;
  /** Yearly transmission fees */
  yearlyEnergyFund: PriceSpecification;
};

export type FuseAndHomeTypePrices = {
  currentRangePrices: Array<CurrentRangePrice>;
  yearlyPriceAboveCurrentRanges: PriceSpecification;
};

/**
 * Capacity model where grid voltage and/or fuse size make the capacity steps.
 *
 * Capacity model used by Alut, NettiNord, SuNett.
 */
export type FuseAndVoltageSize = {
  /** Prices for one phase networks */
  onePhasePrices: PhasePrices;
  /** Prices for three phase networks */
  threePhasePrices: PhasePrices;
  type: CapacityModelType;
};

/**
 * Capacity model where grid voltage and/or fuse size make the capacity steps.
 *
 * Capacity model used by Alut, NettiNord, SuNett.
 */
export type FuseAndVoltageSizeBusiness = {
  /** Prices for one phase networks */
  onePhasePrices: PhasePrices;
  /** Prices for three phase networks */
  threePhasePrices: PhasePrices;
  type: CapacityModelBusinessType;
  yearlyEnergyFund: PriceSpecification;
};

/** Energy model that starts fuse based, but where the customers can select different models in some cases */
export type FuseBasedSelectable = {
  onePhasePrices: SelectablePhasePrices;
  threePhasePrices: SelectablePhasePrices;
  type: EnergyModelType;
};

/** A model that starts fuse based, but where the customers can select different models in some cases */
export type FuseBasedSelectableBusiness = {
  onePhasePrices: SelectablePhasePricesBusiness;
  threePhasePrices: SelectablePhasePricesBusiness;
  type: EnergyModelBusinessType;
};

export type FuseBasedSelectableSeasonal = {
  regular: Array<CurrentRangeMonthlyPrice>;
  seasonal: Array<CurrentRangeMonthlyPrice>;
};

/** Input for the `getChargeSettings` query */
export type GetChargeSettingsInput = {
  vehicleId: Scalars['String'];
};

/** Payload for the `getChargeSettings` query */
export type GetChargeSettingsPayload = {
  chargeSettings: ChargeSettings;
};

export type GetJedlixChargeSettingsInput = {
  vehicleId: Scalars['String'];
};

export type GpsDisabledConfiguredIntegrationVendorVehicleLocationState = {
  type: ConfiguredIntegrationVendorVehicleLocationStateType;
};

export type GpsNeverActivatedConfiguredIntegrationVendorVehicleLocationState = {
  type: ConfiguredIntegrationVendorVehicleLocationStateType;
};

export type GrantDeviceAccessInput = {
  deviceId: Scalars['String'];
  permittedIdentity: EmailIdentityInput;
};

export type HarkUserToken = {
  accessToken?: Maybe<Scalars['String']>;
  /** ISO-8601 */
  created?: Maybe<Scalars['DateTime']>;
  devices?: Maybe<Array<Maybe<Fjutt>>>;
  harkUserId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

/**
 * Energy model were users can select whether they want a seasonal or constant price model (in combination with the capacity model)
 *
 * Energy model used in some Swedish regions.
 */
export type HomeBasedSelectableSeasonal = {
  /** Prices for apartments */
  apartments: PricesAndTaxes;
  /** Prices for houses */
  houses: SelectableSeasonal;
  type: EnergyModelType;
};

export type HourRange = {
  /** 0-23, including start */
  from: Scalars['Int'];
  /** 0-23, excluding end */
  until: Scalars['Int'];
};

/** An alert that is evaluated 30 minutes into the hour and checks if the device's energy consumption is about to exceed a set limit. */
export type HourlyConsumptionLimitEstimationWarningAlertSetting = {
  alertType: AlertType;
  createdBy: AlertSettingCreator;
  deviceId: Scalars['String'];
  id: Scalars['ID'];
  limitInWatts: Scalars['Int'];
  notificationChannels: Array<NotificationChannel>;
};

/** An event type that is used if HourlyConsumptionLimitEstimationWarningAlertSetting was triggered. */
export type HourlyConsumptionReachedEvent = {
  activeEnergyImported: Scalars['Float'];
  alertSettingId: Scalars['String'];
  alertType: SentAlertAlertType;
  at: Scalars['Date'];
  deviceId: Scalars['String'];
  intermediaryId?: Maybe<Scalars['String']>;
  limitInWatts: Scalars['Float'];
  notificationChannelType: NotificationChannelType;
  type: AlertEventType;
  userId?: Maybe<Scalars['String']>;
};

/** An event type that describes the HourlyConsumptionSustainableEvent event. */
export type HourlyConsumptionSustainableEvent = {
  activePowerImportAverage: Scalars['Float'];
  alertSettingId: Scalars['String'];
  alertType: SentAlertAlertType;
  at: Scalars['Date'];
  consumptionThisFarInTheCurrentHourInWatts: Scalars['Float'];
  deviceId: Scalars['String'];
  intermediaryId?: Maybe<Scalars['String']>;
  limitInWatts: Scalars['Float'];
  notificationChannelType: NotificationChannelType;
  type: AlertEventType;
  userId?: Maybe<Scalars['String']>;
};

/** An event type that describes the HourlyConsumptionLimitEstimationWarningAlertSetting event. */
export type HourlyConsumptionWarningEvent = {
  activePowerImportAverage: Scalars['Float'];
  alertSettingId: Scalars['String'];
  alertType: SentAlertAlertType;
  at: Scalars['Date'];
  consumptionThisFarInTheCurrentHourInWatts: Scalars['Float'];
  deviceId: Scalars['String'];
  intermediaryId?: Maybe<Scalars['String']>;
  limitInWatts: Scalars['Float'];
  notificationChannelType: NotificationChannelType;
  type: AlertEventType;
  userId?: Maybe<Scalars['String']>;
};

export type Identity = EmailIdentity | ExternalUserIdentity | IntermediaryApiKeyIdentity | InternalIdentity;

export type IdentityBase = {
  type: IdentityType;
};

export enum IdentityType {
  EmailIdentity = 'EmailIdentity',
  ExternalUserIdentity = 'ExternalUserIdentity',
  IntermediaryApiKeyIdentity = 'IntermediaryApiKeyIdentity',
  InternalIdentity = 'InternalIdentity'
}

/** Ting som har med innlogging og tilganger knyttes til dette objektet. Andre ting knyttes til Brukerprofil */
export type InnloggetBruker = {
  harkToken?: Maybe<HarkUserToken>;
  id?: Maybe<Scalars['ID']>;
  tilganger?: Maybe<Array<Maybe<Scalars['String']>>>;
  trackingId?: Maybe<Scalars['String']>;
};

export type InnmeldingInput = {
  fakturamerke?: InputMaybe<Scalars['String']>;
  maalepunkter?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** ISO-8601 */
  oppstartdato?: InputMaybe<Scalars['Date']>;
  tariffId?: InputMaybe<Scalars['String']>;
};

export type InnmeldingStatus = {
  error?: Maybe<Scalars['String']>;
  fakturamerke?: Maybe<Scalars['String']>;
  maalepunkter?: Maybe<Array<Maybe<Scalars['String']>>>;
  ordreId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type InnmeldtAnleggInput = {
  avlesning?: InputMaybe<Avlesning>;
  etasje?: InputMaybe<Scalars['String']>;
  gateadresse?: InputMaybe<Scalars['String']>;
  maalepunktId?: InputMaybe<Scalars['String']>;
  maalernummer?: InputMaybe<Scalars['String']>;
  postnummer?: InputMaybe<Scalars['String']>;
  poststed?: InputMaybe<Scalars['String']>;
};

export type Innstilling = {
  navn?: Maybe<Scalars['String']>;
  verdi?: Maybe<Scalars['String']>;
};

export type InnstillingInput = {
  navn?: InputMaybe<Scalars['String']>;
  verdi?: InputMaybe<Scalars['String']>;
};

export type IntegrationAuthorizationUrlInput = {
  vendor: IntegrationVendor;
};

export type IntegrationAuthorizationUrlResponse = {
  authorizationUrl: Scalars['String'];
};

export type IntegrationSessionInput = {
  integration: IntegrationType;
  redirectUrl: Scalars['String'];
};

export type IntegrationSessionPayload = {
  url: Scalars['String'];
};

export enum IntegrationType {
  MILL = 'MILL',
  ZAPTEC = 'ZAPTEC'
}

export enum IntegrationVendor {
  AUDI = 'AUDI',
  BMW = 'BMW',
  CUPRA = 'CUPRA',
  JAGUAR = 'JAGUAR',
  MINI = 'MINI',
  PORSCHE = 'PORSCHE',
  RENAULT = 'RENAULT',
  SEAT = 'SEAT',
  SKODA = 'SKODA',
  TESLA = 'TESLA',
  VOLKSWAGEN = 'VOLKSWAGEN',
  VOLVO = 'VOLVO'
}

export type IntegrationVendorInfo = ConfiguredIntegrationVendorInfo | NotConfiguredIntegrationVendorInfo;

export enum IntegrationVendorStatus {
  CONFIGURED = 'CONFIGURED',
  NOT_CONFIGURED = 'NOT_CONFIGURED'
}

export type IntermediaryAlertSettingCreator = {
  intermediaryId: Scalars['String'];
  type: AlertSettingCreatorType;
};

export type IntermediaryAlertSettingsFilter = {
  deviceIds?: InputMaybe<Array<Scalars['String']>>;
};

export type IntermediaryAlertSettingsInput = {
  filter?: InputMaybe<IntermediaryAlertSettingsFilter>;
};

/** Intermediary api keys are authorized at the intermediate level, to add or remove users on behalf of the intermediary. */
export type IntermediaryApiKey = {
  apiKey?: Maybe<Scalars['String']>;
  created: CreationInfo;
  id: Scalars['ID'];
  intermediaryId: Scalars['String'];
  name: Scalars['String'];
  secret?: Maybe<Scalars['String']>;
  startOfSecret: Scalars['String'];
  /** @deprecated Use `apiKey` */
  token?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Date'];
};

export type IntermediaryApiKeyIdentity = IdentityBase & {
  apiKeyHash: Scalars['String'];
  type: IdentityType;
};

export type IntermediaryApiKeyPayload = {
  intermediaryApiKey: IntermediaryApiKey;
};

export type IntermediaryApiKeys = {
  keys: Array<IntermediaryApiKey>;
};

export type IntermediaryApiKeysResult = IntermediaryApiKeys;

/** An intermediary is the entity or organization that has support responsibility for the devices. */
export type IntermediaryInfo = {
  apiKeys?: Maybe<IntermediaryApiKeysResult>;
  deviceStats?: Maybe<DeviceStats>;
  devices?: Maybe<Array<DeviceInfo>>;
  id: Scalars['ID'];
  permissionScopes?: Maybe<Array<Scalars['String']>>;
};


/** An intermediary is the entity or organization that has support responsibility for the devices. */
export type IntermediaryInfoDevicesArgs = {
  search?: InputMaybe<Search>;
};

export enum IntermediaryUserScope {
  READ_BUFFERED_MEASUREMENTS = 'READ_BUFFERED_MEASUREMENTS',
  READ_DEVICE_INFO = 'READ_DEVICE_INFO',
  READ_HOURLY_REPORTS = 'READ_HOURLY_REPORTS',
  READ_MEASUREMENT_STREAM = 'READ_MEASUREMENT_STREAM'
}

export type IntermediaryUserTokenPayload = {
  accessToken: Scalars['String'];
  user: UserInfo;
};

export type IntermediaryWebhookNotificationChannel = {
  callbackUrl: Scalars['String'];
  metadata?: Maybe<Scalars['JSON']>;
  type: NotificationChannelType;
};

export type IntermediaryWebhookSetting = {
  id: Scalars['String'];
};

export type InternalIdentity = IdentityBase & {
  id: Scalars['String'];
  type: IdentityType;
};

export enum Interval {
  DAILY = 'DAILY',
  HOURLY = 'HOURLY',
  MONTHLY = 'MONTHLY'
}

export enum IntervalDuration {
  HOUR = 'HOUR',
  QUARTER_HOUR = 'QUARTER_HOUR'
}

export type Invitasjon = {
  appId?: Maybe<Scalars['String']>;
  bedriftId?: Maybe<Scalars['String']>;
  bruker?: Maybe<AkseptertBruker>;
  epost?: Maybe<Scalars['String']>;
  /** ISO-8601 */
  foedselsdato?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  moduler?: Maybe<Array<Maybe<Scalars['String']>>>;
  oppdaterKontakt?: Maybe<Scalars['Boolean']>;
  /** ISO-8601 */
  opprettet?: Maybe<Scalars['DateTime']>;
  token?: Maybe<Scalars['String']>;
};

export type InvitasjonsmottakerInput = {
  epost?: InputMaybe<Scalars['String']>;
  kundenr?: InputMaybe<Scalars['Int']>;
  orgnr: Scalars['ID'];
};

export enum Invitasjonsstatus {
  AKSEPTERT = 'AKSEPTERT',
  AVSLAATT = 'AVSLAATT',
  FINNES_IKKE = 'FINNES_IKKE',
  IKKE_AKSEPTERT = 'IKKE_AKSEPTERT'
}

export type InvitasjonsstatusOgOrgNavn = {
  organisasjonsnavn?: Maybe<Scalars['String']>;
  status: Invitasjonsstatus;
};

export enum InvoiceStatus {
  DUE = 'DUE',
  DUNNING = 'DUNNING',
  OVERDUE = 'OVERDUE',
  PAID = 'PAID'
}

export enum InvoiceType {
  CREDITED = 'CREDITED',
  CREDIT_NOTE = 'CREDIT_NOTE',
  INVOICE = 'INVOICE'
}

export type JedlixActiveNotificationsPayload = {
  notifications: Array<Maybe<JedlixNotification>>;
};

export type JedlixAddress = {
  city?: Maybe<Scalars['String']>;
  country: CountryCode;
  houseNumber?: Maybe<Scalars['String']>;
  postalCode: Scalars['String'];
  priceArea?: Maybe<EnergyBiddingZone>;
  region?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
};

export type JedlixAddressInput = {
  city?: InputMaybe<Scalars['String']>;
  country: CountryCode;
  houseNumber?: InputMaybe<Scalars['String']>;
  postalCode: Scalars['String'];
  region?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
};

export enum JedlixBrand {
  ABARTH = 'ABARTH',
  AIWAYS = 'AIWAYS',
  AUDI = 'AUDI',
  BENTLEY = 'BENTLEY',
  BMW = 'BMW',
  BYD = 'BYD',
  BYTON = 'BYTON',
  CADILLAC = 'CADILLAC',
  CHEVROLET = 'CHEVROLET',
  CITROEN = 'CITROEN',
  CUPRA = 'CUPRA',
  DACIA = 'DACIA',
  DR_AUTOMOBILE = 'DR_AUTOMOBILE',
  DS = 'DS',
  FIAT = 'FIAT',
  FISKER = 'FISKER',
  FORD = 'FORD',
  GENESIS = 'GENESIS',
  HONDA = 'HONDA',
  HONGQI = 'HONGQI',
  HYUNDAI = 'HYUNDAI',
  JAC = 'JAC',
  JAGUAR = 'JAGUAR',
  JEEP = 'JEEP',
  KARMA = 'KARMA',
  KIA = 'KIA',
  LAND_ROVER = 'LAND_ROVER',
  LEVC = 'LEVC',
  LEXUS = 'LEXUS',
  LIGHTYEAR = 'LIGHTYEAR',
  LOTUS = 'LOTUS',
  LUCID = 'LUCID',
  LYNC_AND_CO = 'LYNC_AND_CO',
  MASERATI = 'MASERATI',
  MAZDA = 'MAZDA',
  MERCEDES = 'MERCEDES',
  MG = 'MG',
  MINI = 'MINI',
  MITSUBISHI = 'MITSUBISHI',
  NIO = 'NIO',
  NISSAN = 'NISSAN',
  OPEL = 'OPEL',
  PEUGEOT = 'PEUGEOT',
  POLESTAR = 'POLESTAR',
  PORSCHE = 'PORSCHE',
  RENAULT = 'RENAULT',
  ROLLS_ROYCE = 'ROLLS_ROYCE',
  SEAT = 'SEAT',
  SERES = 'SERES',
  SKODA = 'SKODA',
  SMART = 'SMART',
  SONO = 'SONO',
  SSANG_YONG = 'SSANG_YONG',
  SUBARU = 'SUBARU',
  SUZUKI = 'SUZUKI',
  TESLA = 'TESLA',
  TEST = 'TEST',
  TOYOTA = 'TOYOTA',
  VINFAST = 'VINFAST',
  VOLKSWAGEN = 'VOLKSWAGEN',
  VOLVO = 'VOLVO',
  XPENG = 'XPENG'
}

export type JedlixChargeState = {
  batteryLevel: Scalars['Float'];
  chargePower: Scalars['Float'];
  chargingLocationId?: Maybe<Scalars['String']>;
  chargingStatus: VehicleChargingStatus;
  range: Scalars['Float'];
  updatedAt: Scalars['Date'];
};

export type JedlixChargingHistoryInput = {
  endTimeFrom: Scalars['Date'];
  interval: Scalars['Int'];
  startTimeTo: Scalars['Date'];
  vehicleId: Scalars['String'];
};

export type JedlixChargingHistoryLocation = {
  chargingLocationId: Scalars['String'];
  country: CountryCode;
  priceArea?: Maybe<EnergyBiddingZone>;
};

export type JedlixChargingHistoryPayload = {
  chargingLocations: Array<Maybe<JedlixChargingHistoryLocation>>;
  chargingSessionsInInterval: Array<Maybe<ChargingSession>>;
};

export type JedlixChargingLocation = {
  address: JedlixAddress;
  coordinates: LatLng;
  id: Scalars['String'];
  userId: Scalars['String'];
};

export type JedlixChargingLocationInput = {
  chargingLocationId: Scalars['String'];
};

export type JedlixChargingLocationPayload = {
  chargingLocation: JedlixChargingLocation;
};

export type JedlixChargingLocationsPayload = {
  chargingLocations: Array<JedlixChargingLocation>;
};

export type JedlixConnection = {
  id: Scalars['String'];
  isFinished: Scalars['Boolean'];
  redirectInfo?: Maybe<JedlixConnectionRedirectInfo>;
  redirectUrl?: Maybe<Scalars['String']>;
  startUrl?: Maybe<Scalars['String']>;
};

export type JedlixConnectionRedirectInfo = {
  includeBody?: Maybe<Scalars['Boolean']>;
  includeCookies: Array<Maybe<Scalars['String']>>;
  includeRedirectUrl?: Maybe<Scalars['Boolean']>;
};

export type JedlixNotification = {
  createdAt: Scalars['Date'];
  dismissedAt?: Maybe<Scalars['Date']>;
  id: Scalars['String'];
  isDismissable: Scalars['Boolean'];
  messageContext: Scalars['String'];
  notificationType: JedlixNotificationType;
  title: Scalars['String'];
  type: MessageType;
};

export enum JedlixNotificationType {
  BANNER = 'BANNER',
  EMAIL = 'EMAIL',
  PUSH_NOTIFICATION = 'PUSH_NOTIFICATION',
  SMS = 'SMS'
}

export type JedlixUserPayload = {
  chargingLocations?: Maybe<Array<Maybe<JedlixChargingLocation>>>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  locale: Scalars['String'];
};

export type JedlixVehicle = {
  capabilities: VehicleCapabilities;
  chargeState?: Maybe<JedlixChargeState>;
  details?: Maybe<VehicleInfo>;
  id: Scalars['String'];
  isConnectable: Scalars['Boolean'];
  isConnected: Scalars['Boolean'];
};

export type Kontaktinfo = {
  adresse1?: Maybe<Scalars['String']>;
  adresse2?: Maybe<Scalars['String']>;
  epost?: Maybe<Scalars['String']>;
  postnummer?: Maybe<Scalars['String']>;
  poststed?: Maybe<Scalars['String']>;
  telefonnummer?: Maybe<Scalars['String']>;
};

export type Kontaktinformasjon = {
  adresse?: Maybe<Scalars['String']>;
  epost?: Maybe<Scalars['String']>;
  kontaktperson?: Maybe<Scalars['String']>;
  postnummer?: Maybe<Scalars['String']>;
  poststed?: Maybe<Scalars['String']>;
  telefonnummer?: Maybe<Scalars['String']>;
};

export type Kunde = {
  bedrift?: Maybe<Bedrift>;
  epost?: Maybe<Scalars['String']>;
  kundenummer?: Maybe<Scalars['String']>;
  navn?: Maybe<Scalars['String']>;
  orgnummer?: Maybe<Scalars['String']>;
};

export type KundeInput = {
  epost?: InputMaybe<Scalars['String']>;
  etternavn?: InputMaybe<Scalars['String']>;
  fornavn?: InputMaybe<Scalars['String']>;
  gate?: InputMaybe<Scalars['String']>;
  nummer?: InputMaybe<Scalars['String']>;
  postnummer?: InputMaybe<Scalars['String']>;
  poststed?: InputMaybe<Scalars['String']>;
  telefonnummer?: InputMaybe<Scalars['String']>;
};

export type KundereferanseInput = {
  contractGuid: Scalars['String'];
};

export type KundesystemBedrift = {
  kontaktinfo?: Maybe<Kontaktinfo>;
  kundeId?: Maybe<Scalars['String']>;
  navn?: Maybe<Scalars['String']>;
  orgnummer: Scalars['ID'];
};

export type LatLng = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type LatLngInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export enum Level {
  ERROR = 'ERROR',
  INFO = 'INFO'
}

export type Linje = {
  beloepEksMoms?: Maybe<Scalars['Float']>;
  beloepMoms?: Maybe<Scalars['Float']>;
  fakturalinje?: Maybe<Scalars['String']>;
  /** ISO-8601 */
  fradato?: Maybe<Scalars['Date']>;
  prissatsEksMoms?: Maybe<Scalars['Float']>;
  produkt?: Maybe<Scalars['String']>;
  produktkode?: Maybe<Scalars['String']>;
  produkttype?: Maybe<Scalars['String']>;
  salgsordre?: Maybe<Scalars['Int']>;
  /** ISO-8601 */
  tildato?: Maybe<Scalars['Date']>;
};

export type ListJedlixVehiclesPayload = {
  vehicles: Array<Maybe<JedlixVehicle>>;
};

export type Location = {
  controllableIds: Array<Maybe<Scalars['String']>>;
  coordinates: LatLng;
  deviceId?: Maybe<Scalars['String']>;
  hourlyEnergyConsumptionLimit?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  name: Scalars['String'];
};

export type LocationAvailableConfiguredIntegrationVendorVehicleLocationState = {
  lastUpdatedAt: Scalars['String'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  type: ConfiguredIntegrationVendorVehicleLocationStateType;
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type LoginPayload = {
  session: SessionInfo;
  user: UserInfo;
};

export type Maalergruppering = {
  beskrivelse?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  maalerpunkter?: Maybe<Array<Maybe<Scalars['String']>>>;
  navn?: Maybe<Scalars['String']>;
  opprettetAv?: Maybe<Scalars['String']>;
  orgnummer?: Maybe<Scalars['String']>;
};

export type MaalergrupperingInput = {
  beskrivelse?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  maalerpunkter?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  navn?: InputMaybe<Scalars['String']>;
  opprettetAv?: InputMaybe<Scalars['String']>;
  orgnummer?: InputMaybe<Scalars['String']>;
};

export type Measurement = {
  /** Active energy exported, in Wh */
  activeEnergyExported?: Maybe<Scalars['Float']>;
  /** Active energy imported, in Wh */
  activeEnergyImported?: Maybe<Scalars['Float']>;
  /** Active power export, in W */
  activePowerExport?: Maybe<Scalars['Float']>;
  /** Active power import, in W */
  activePowerImport?: Maybe<Scalars['Float']>;
  /** Current, phase 1, in A */
  currentPhase1?: Maybe<Scalars['Float']>;
  /** Current, phase 2, in A */
  currentPhase2?: Maybe<Scalars['Float']>;
  /** Current, phase 3, in A */
  currentPhase3?: Maybe<Scalars['Float']>;
  /** Device ID */
  deviceId?: Maybe<Scalars['String']>;
  /** Reactive energy exported, in varh */
  reactiveEnergyExported?: Maybe<Scalars['Float']>;
  /** Reactive energy imported, in varh */
  reactiveEnergyImported?: Maybe<Scalars['Float']>;
  /** Reactive power export, in var */
  reactivePowerExport?: Maybe<Scalars['Float']>;
  /** Reactive power import, in var */
  reactivePowerImport?: Maybe<Scalars['Float']>;
  /** Time in UTC */
  time: Scalars['Date'];
  /** Voltage, phase 1, in V */
  voltagePhase1?: Maybe<Scalars['Float']>;
  /** Voltage, phase 2, in V */
  voltagePhase2?: Maybe<Scalars['Float']>;
  /** Voltage, phase 3, in V */
  voltagePhase3?: Maybe<Scalars['Float']>;
};

export type MessageInput = {
  level: Level;
  message: Scalars['String'];
  title: Scalars['String'];
};

export enum MessageType {
  AUDI_NOT_PAIRED = 'AUDI_NOT_PAIRED',
  AUDI_PAIRING_NOT_COMPLETE = 'AUDI_PAIRING_NOT_COMPLETE',
  AUDI_REMOTE_ACCESS_DISABLED = 'AUDI_REMOTE_ACCESS_DISABLED',
  AUDI_REMOTE_ACCESS_DISABLED_BANNER = 'AUDI_REMOTE_ACCESS_DISABLED_BANNER',
  CAR_NOT_FOUND = 'CAR_NOT_FOUND',
  CAR_NOT_REACHABLE = 'CAR_NOT_REACHABLE',
  CHECK_CAR_ACTIVATION = 'CHECK_CAR_ACTIVATION',
  END_SESSION = 'END_SESSION',
  GEO_LOCATION_DISABLED = 'GEO_LOCATION_DISABLED',
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
  INVALID_CREDENTIALS_BANNER = 'INVALID_CREDENTIALS_BANNER',
  INVALID_UTILITY_CONTRACT = 'INVALID_UTILITY_CONTRACT',
  MY_RENAULT_ACCESS_REVOKED = 'MY_RENAULT_ACCESS_REVOKED',
  READY_FOR_PAIRING = 'READY_FOR_PAIRING',
  RECEIVED_NO_POWER = 'RECEIVED_NO_POWER',
  RECONNECT_UTILITY_CONTRACT = 'RECONNECT_UTILITY_CONTRACT',
  SESSION_IMPOSSIBLE = 'SESSION_IMPOSSIBLE',
  START_SESSION = 'START_SESSION',
  ZE_INTERACTIVE_EXPIRES_SOON = 'ZE_INTERACTIVE_EXPIRES_SOON',
  ZE_INTERACTIVE_TO_BE_ACTIVATED = 'ZE_INTERACTIVE_TO_BE_ACTIVATED'
}

/** Metadata about our services */
export type Metadata = {
  /** Required version to run the app. The app should block the user from using an older version than this. */
  requiredAppVersion: Scalars['String'];
  /** Suggested version to run the app. The app will present the user with information about expecting errors when running older versions of the app. */
  suggestedAppVersion: Scalars['String'];
};

export enum MeterProducer {
  AIDON = 'AIDON',
  EMBRIQ = 'EMBRIQ',
  KAIFA = 'KAIFA',
  KAMSTRUP = 'KAMSTRUP',
  UNRECOGNIZED = 'UNRECOGNIZED'
}

export type MillSignInInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type MillSignInPayload = {
  success: Scalars['Boolean'];
};

export type Modul = {
  id?: Maybe<Scalars['ID']>;
  navn?: Maybe<Scalars['String']>;
};

export enum Month {
  APRIL = 'APRIL',
  AUGUST = 'AUGUST',
  DECEMBER = 'DECEMBER',
  FEBRUARY = 'FEBRUARY',
  JANUARY = 'JANUARY',
  JULY = 'JULY',
  JUNE = 'JUNE',
  MARCH = 'MARCH',
  MAY = 'MAY',
  NOVEMBER = 'NOVEMBER',
  OCTOBER = 'OCTOBER',
  SEPTEMBER = 'SEPTEMBER'
}

export type MonthRange = {
  /** 1-12, including start */
  from: Scalars['Int'];
  /** 1-12, exluding end */
  until: Scalars['Int'];
};

export type MonthWeights = {
  april: Scalars['Float'];
  august: Scalars['Float'];
  december: Scalars['Float'];
  february: Scalars['Float'];
  january: Scalars['Float'];
  july: Scalars['Float'];
  june: Scalars['Float'];
  march: Scalars['Float'];
  may: Scalars['Float'];
  november: Scalars['Float'];
  october: Scalars['Float'];
  september: Scalars['Float'];
};

/**
 * Capacity model where (e.g. 3) daily maximas for a month is averaged and used to
 * determine the capacity step. The steps has a range in Wh with a price, and
 * there is also a field defining the price for when all capacity steps are
 * exceeded.
 */
export type MonthlyPeaksAtDifferentDays = {
  capacitySteps: Array<CapacityStep>;
  peaksPerMonth: Scalars['Int'];
  priceAboveCapacitySteps: PriceSpecification;
  type: CapacityModelType;
};

/**
 * Capacity model where (e.g. 3) daily maximas for a month is averaged and used to
 * determine the capacity step. The steps has a range in Wh with a price, and
 * there is also a field defining the price for when all capacity steps are
 * exceeded.
 *
 * Includes a yearly energy fund fee.
 */
export type MonthlyPeaksAtDifferentDaysBusiness = {
  capacitySteps: Array<CapacityStep>;
  peaksPerMonth: Scalars['Int'];
  priceAboveCapacitySteps: PriceSpecification;
  type: CapacityModelBusinessType;
  yearlyEnergyFund: PriceSpecification;
};

/**
 * Capacity model where (e.g. 3) daily maximas for a month is averaged and
 * multiplied by `pricePerKwhForAverageOfPeaks` and added to `baseFeePerYear`.
 *
 * Capacity model used by SkiakerNett, but with only one maxima.
 *
 * <https://skiakernett.no/nettleige/>
 */
export type MonthlyPeaksAtDifferentDaysLinearWithBase = {
  baseFeePerYear: PriceSpecification;
  /** The number of daily peaks that will be averaged to find the capacity step. */
  peaksPerMonth: Scalars['Int'];
  pricePerKwhForAverageOfPeaks: PriceSpecification;
  type: CapacityModelType;
};

export type MonthlyPriceSpecification = {
  monthlyPrice: PriceSpecification;
};

/** Mutation root */
export type Mutation = {
  addChargingLocation: AddChargingLocationPayload;
  addChargingPermission?: Maybe<AddChargingPermissionPayload>;
  /** Add device permissions to a user. */
  addDevicePermissions?: Maybe<AddDevicePermissionsPayload>;
  /** Add a dynamic alert that continuously evaluates whether the device has crossed a set limit for energy consumption within the hour. */
  addEstimatedHourlyActiveEnergyLimitWarningAlertSetting: EstimatedHourlyActiveEnergyLimitWarningAlertSetting;
  addFjutt?: Maybe<AddFjuttResponse>;
  /** Add an alert that is evaluated 30 minutes into the hour and checks if the device's energy consumption is about to exceed a set limit. */
  addHourlyConsumptionLimitEstimationWarningAlertSetting: HourlyConsumptionLimitEstimationWarningAlertSetting;
  addIntermediaryApiKey: IntermediaryApiKeyPayload;
  addIntermediaryWebhookNotificationChannelToAlertSetting: AddIntermediaryWebhookNotificationChannelToAlertSettingPayload;
  /** Add a dynamic alert that continuously evaluates whether the device has crossed a set limit for energy consumption within the hour. */
  addSustainableConsumptionAlertSetting: SustainableConsumptionAlertSetting;
  /** Create a user using an external user identifier of your choice. */
  addUser: AddUserPayload;
  /** Create an access token for a user. */
  addUserSession: AddUserSessionPayload;
  aksepterInvitasjon?: Maybe<AkseptertInvitasjon>;
  avslaaInvitasjon?: Maybe<AvslaaInvitasjon>;
  batchMeldInnAnlegg?: Maybe<BatchInnmeldingStatus>;
  beOmValideringskode?: Maybe<Scalars['String']>;
  /** Cancel an Order */
  cancelOrder: Order;
  claimDevice: DeviceInfo;
  /** @deprecated Deprecated. Please use ordersForUser, addUser, addDevicePermissions, and addUserSession individually instead. */
  connectExternalUser: ConnectExternalUserPayload;
  deleteAlertSetting: DeleteAlertSettingPayload;
  deleteChargingLocation: DeleteChargingLocationPayload;
  deleteDeviceName?: Maybe<DeviceInfo>;
  deleteDistributedDocument?: Maybe<Scalars['String']>;
  deleteMe: DeleteMePayload;
  endreFaktureringsmaate?: Maybe<Scalars['String']>;
  etablerBedrift?: Maybe<Nyetablering>;
  fjernMedlem?: Maybe<Bedrift>;
  fjernModul?: Maybe<Bedrift>;
  fjernModulmedlem?: Maybe<Bedrift>;
  grantDeviceAccess: Permission;
  inviterBulk?: Maybe<Array<Maybe<Bulkstatus>>>;
  /** Lagrer innstillinger for innlogget bruker */
  lagreBrukerprofilInnstillinger?: Maybe<Array<Maybe<Innstilling>>>;
  lagreForbruksvarsel: Forbruksvarsel;
  lagreMaalergruppering?: Maybe<Brukerprofil>;
  lagreVarsling?: Maybe<Varsling>;
  leggTilMedlem?: Maybe<Bedrift>;
  leggTilModul?: Maybe<Bedrift>;
  leggTilModulmedlem?: Maybe<Bedrift>;
  logFrontend?: Maybe<Scalars['String']>;
  login: LoginPayload;
  meldInnAnlegg?: Maybe<Scalars['String']>;
  meldInnAnleggForBedrift?: Maybe<Scalars['String']>;
  meldUtAnlegg?: Maybe<Scalars['String']>;
  oppdaterAvslaattInvitasjon?: Maybe<AvslaaInvitasjon>;
  oppdaterBedrift?: Maybe<Bedrift>;
  oppdaterEgenAnleggsbeskrivelse?: Maybe<Scalars['String']>;
  opprettBediftFraKundereferanse?: Maybe<Bedrift>;
  opprettBedrift?: Maybe<Bedrift>;
  opprettInvitasjon?: Maybe<Invitasjon>;
  /** Order a device */
  orderDevice: OrderDevicePayload;
  removeChargingPermission?: Maybe<RemoveChargingPermissionPayload>;
  /** Remove device permissions from a user. */
  removeDevicePermissions?: Maybe<RemoveDevicePermissionsPayload>;
  removeFjutt?: Maybe<Scalars['String']>;
  removeIntermediaryApiKey: IntermediaryApiKeyPayload;
  removeNotificationChannelFromAlertSetting: RemoveNotificationChannelFromAlertSettingPayload;
  /** Remove a user from the system. */
  removeUser: RemoveUserPayload;
  resendInvitasjon?: Maybe<Invitasjon>;
  revokeDeviceAccess?: Maybe<Scalars['Boolean']>;
  setDeviceActivationStatus: SetDeviceActivationStatusPayload;
  setDocumentRead?: Maybe<CompanyDocument>;
  setIntermediaryWebhookSetting: SetIntermediaryWebhookSettingPayload;
  settBrukermoduler?: Maybe<Bedrift>;
  settGodkjenteTariffer?: Maybe<Bedrift>;
  slettBedrift?: Maybe<Scalars['String']>;
  slettForbruksvarsel?: Maybe<Forbruksvarsel>;
  /** Slett invitasjon, returnerer invitasjonId */
  slettInvitasjon?: Maybe<Scalars['ID']>;
  slettMaalergruppering?: Maybe<Brukerprofil>;
  slettVarsling?: Maybe<Varsling>;
  testWebhookSetting: TestWebhookSettingPayload;
  updateChargingLocation: UpdateChargingLocationPayload;
  updateCompaniesForDocument?: Maybe<DistributedDocument>;
  /**
   * Updates the device name
   *
   * Since the `deviceName` input is mandatory, there is another mutation to delete the device name.
   */
  updateDeviceName?: Maybe<DeviceInfo>;
  /**
   * Update device owner information. Will not change the info about who the device was shipped to.
   *
   * `deviceOwner` will be replaced by the new input object, and missing fields will be removed.
   */
  updateDeviceOwner?: Maybe<DeviceInfo>;
  /** Update the limit for when an alert should be triggered for the dynamic alert. */
  updateEstimatedHourlyActiveEnergyLimitWarningAlertSetting: UpdateEstimatedHourlyActiveEnergyLimitWarningAlertSettingPayload;
  /** Update the limit for when an alert should be triggered for the half-hour alert. */
  updateHourlyConsumptionLimitEstimationWarningAlertSetting: UpdateHourlyConsumptionLimitEstimationWarningAlertSettingPayload;
  updateSmartChargingPolicy: UpdateSmartChargingPolicyPayload;
  /** Update the limit for when an alert should be triggered for the dynamic alert. */
  updateSustainableConsumptionAlertSetting: UpdateSustainableConsumptionAlertSettingPayload;
  validerEpost?: Maybe<Valideringsresultat>;
};


/** Mutation root */
export type MutationAddChargingLocationArgs = {
  input: AddChargingLocationInput;
};


/** Mutation root */
export type MutationAddChargingPermissionArgs = {
  input: AddChargingPermissionInput;
};


/** Mutation root */
export type MutationAddDevicePermissionsArgs = {
  input: AddDevicePermissionsInput;
};


/** Mutation root */
export type MutationAddEstimatedHourlyActiveEnergyLimitWarningAlertSettingArgs = {
  input: AddEstimatedHourlyActiveEnergyLimitWarningAlertSettingInput;
};


/** Mutation root */
export type MutationAddFjuttArgs = {
  anleggsId?: InputMaybe<Scalars['String']>;
  deviceId?: InputMaybe<Scalars['String']>;
  orgId: Scalars['String'];
};


/** Mutation root */
export type MutationAddHourlyConsumptionLimitEstimationWarningAlertSettingArgs = {
  input: AddHourlyConsumptionLimitEstimationWarningAlertSettingInput;
};


/** Mutation root */
export type MutationAddIntermediaryApiKeyArgs = {
  input: AddIntermediaryApiKeyInput;
};


/** Mutation root */
export type MutationAddIntermediaryWebhookNotificationChannelToAlertSettingArgs = {
  input: AddIntermediaryWebhookNotificationChannelToAlertSettingInput;
};


/** Mutation root */
export type MutationAddSustainableConsumptionAlertSettingArgs = {
  input: AddSustainableConsumptionAlertSettingInput;
};


/** Mutation root */
export type MutationAddUserArgs = {
  input: AddUserInput;
};


/** Mutation root */
export type MutationAddUserSessionArgs = {
  input: AddUserSessionInput;
};


/** Mutation root */
export type MutationAksepterInvitasjonArgs = {
  aksepterInvitasjon?: InputMaybe<AksepterInvitasjonInput>;
};


/** Mutation root */
export type MutationAvslaaInvitasjonArgs = {
  avslaa: AvslaaInvitasjonInput;
};


/** Mutation root */
export type MutationBatchMeldInnAnleggArgs = {
  innmeldingAnlegg: BatchInnmeldingAnleggInput;
  orgid: Scalars['ID'];
};


/** Mutation root */
export type MutationBeOmValideringskodeArgs = {
  epost: Scalars['String'];
};


/** Mutation root */
export type MutationCancelOrderArgs = {
  input: CancelOrderInput;
};


/** Mutation root */
export type MutationClaimDeviceArgs = {
  claimId: Scalars['ClaimId'];
};


/** Mutation root */
export type MutationConnectExternalUserArgs = {
  input: ConnectExternalUserInput;
};


/** Mutation root */
export type MutationDeleteAlertSettingArgs = {
  input: DeleteAlertSettingInput;
};


/** Mutation root */
export type MutationDeleteChargingLocationArgs = {
  input: DeleteChargingLocationInput;
};


/** Mutation root */
export type MutationDeleteDeviceNameArgs = {
  deviceId: Scalars['String'];
};


/** Mutation root */
export type MutationDeleteDistributedDocumentArgs = {
  documentId: Scalars['ID'];
};


/** Mutation root */
export type MutationDeleteMeArgs = {
  input: DeleteMeInput;
};


/** Mutation root */
export type MutationEndreFaktureringsmaateArgs = {
  endring: EndreFaktureringsmaateInput;
};


/** Mutation root */
export type MutationEtablerBedriftArgs = {
  body: NyetableringInput;
};


/** Mutation root */
export type MutationFjernMedlemArgs = {
  brukerId: Scalars['ID'];
  orgid: Scalars['ID'];
};


/** Mutation root */
export type MutationFjernModulArgs = {
  modulId: Scalars['ID'];
  orgid: Scalars['ID'];
};


/** Mutation root */
export type MutationFjernModulmedlemArgs = {
  brukerId: Scalars['ID'];
  modulId: Scalars['ID'];
  orgid: Scalars['ID'];
};


/** Mutation root */
export type MutationGrantDeviceAccessArgs = {
  input: GrantDeviceAccessInput;
};


/** Mutation root */
export type MutationInviterBulkArgs = {
  bulkinvitasjon: BulkinvitasjonInput;
};


/** Mutation root */
export type MutationLagreBrukerprofilInnstillingerArgs = {
  innstillinger?: InputMaybe<Array<InputMaybe<InnstillingInput>>>;
};


/** Mutation root */
export type MutationLagreForbruksvarselArgs = {
  varsel: ForbruksvarselInput;
};


/** Mutation root */
export type MutationLagreMaalergrupperingArgs = {
  maalergruppering?: InputMaybe<MaalergrupperingInput>;
};


/** Mutation root */
export type MutationLagreVarslingArgs = {
  varsling?: InputMaybe<VarslingInput>;
};


/** Mutation root */
export type MutationLeggTilMedlemArgs = {
  brukerId: Scalars['ID'];
  orgid: Scalars['ID'];
};


/** Mutation root */
export type MutationLeggTilModulArgs = {
  modul: Scalars['String'];
  orgid: Scalars['ID'];
};


/** Mutation root */
export type MutationLeggTilModulmedlemArgs = {
  brukerId: Scalars['ID'];
  modulId: Scalars['ID'];
  orgid: Scalars['ID'];
};


/** Mutation root */
export type MutationLogFrontendArgs = {
  message: MessageInput;
};


/** Mutation root */
export type MutationLoginArgs = {
  input: LoginInput;
};


/** Mutation root */
export type MutationMeldInnAnleggArgs = {
  anleggsinnmelding: AnleggsinnmeldingInput;
  orgid: Scalars['ID'];
};


/** Mutation root */
export type MutationMeldInnAnleggForBedriftArgs = {
  anleggsinnmelding: Anleggsinnmelding2Input;
};


/** Mutation root */
export type MutationMeldUtAnleggArgs = {
  anleggsutmelding: AnleggsutmeldingInput;
  orgid: Scalars['ID'];
};


/** Mutation root */
export type MutationOppdaterAvslaattInvitasjonArgs = {
  avslaa: AvslaaInvitasjonInput;
};


/** Mutation root */
export type MutationOppdaterBedriftArgs = {
  oppdaterBedrift?: InputMaybe<OppdaterBedriftInput>;
};


/** Mutation root */
export type MutationOppdaterEgenAnleggsbeskrivelseArgs = {
  egenAnleggsbeskrivelse?: InputMaybe<Scalars['String']>;
  maalepunktId: Scalars['ID'];
  orgid: Scalars['ID'];
};


/** Mutation root */
export type MutationOpprettBediftFraKundereferanseArgs = {
  kunderef?: InputMaybe<KundereferanseInput>;
};


/** Mutation root */
export type MutationOpprettBedriftArgs = {
  opprettBedrift?: InputMaybe<OpprettBedriftInput>;
};


/** Mutation root */
export type MutationOpprettInvitasjonArgs = {
  invitasjon?: InputMaybe<OpprettInvitasjonInput>;
  orgid: Scalars['ID'];
};


/** Mutation root */
export type MutationOrderDeviceArgs = {
  input: OrderDeviceInput;
};


/** Mutation root */
export type MutationRemoveChargingPermissionArgs = {
  input: RemoveChargingPermissionInput;
};


/** Mutation root */
export type MutationRemoveDevicePermissionsArgs = {
  input: RemoveDevicePermissionsInput;
};


/** Mutation root */
export type MutationRemoveFjuttArgs = {
  anleggsId?: InputMaybe<Scalars['String']>;
  deviceId?: InputMaybe<Scalars['String']>;
};


/** Mutation root */
export type MutationRemoveIntermediaryApiKeyArgs = {
  input: RemoveIntermediaryApiKeyInput;
};


/** Mutation root */
export type MutationRemoveNotificationChannelFromAlertSettingArgs = {
  input: RemoveNotificationChannelFromAlertSettingInput;
};


/** Mutation root */
export type MutationRemoveUserArgs = {
  input: RemoveUserInput;
};


/** Mutation root */
export type MutationResendInvitasjonArgs = {
  invitasjonId: Scalars['ID'];
  orgid: Scalars['ID'];
};


/** Mutation root */
export type MutationRevokeDeviceAccessArgs = {
  input: RevokeDeviceAccessInput;
};


/** Mutation root */
export type MutationSetDeviceActivationStatusArgs = {
  input: SetDeviceActivationStatusInput;
};


/** Mutation root */
export type MutationSetDocumentReadArgs = {
  companyId: Scalars['ID'];
  documentId: Scalars['ID'];
  read: Scalars['Boolean'];
};


/** Mutation root */
export type MutationSetIntermediaryWebhookSettingArgs = {
  input: SetIntermediaryWebhookSettingInput;
};


/** Mutation root */
export type MutationSettBrukermodulerArgs = {
  brukerId: Scalars['ID'];
  modulIder: Array<Scalars['String']>;
  orgid: Scalars['ID'];
};


/** Mutation root */
export type MutationSettGodkjenteTarifferArgs = {
  orgid: Scalars['ID'];
  tariffIder: Array<InputMaybe<Scalars['String']>>;
};


/** Mutation root */
export type MutationSlettBedriftArgs = {
  orgid: Scalars['ID'];
};


/** Mutation root */
export type MutationSlettForbruksvarselArgs = {
  id: Scalars['ID'];
};


/** Mutation root */
export type MutationSlettInvitasjonArgs = {
  invitasjonId: Scalars['ID'];
  orgid: Scalars['ID'];
};


/** Mutation root */
export type MutationSlettMaalergrupperingArgs = {
  id: Scalars['ID'];
  orgnummer: Scalars['ID'];
};


/** Mutation root */
export type MutationSlettVarslingArgs = {
  id: Scalars['ID'];
};


/** Mutation root */
export type MutationTestWebhookSettingArgs = {
  input: TestWebhookSettingInput;
};


/** Mutation root */
export type MutationUpdateChargingLocationArgs = {
  input: UpdateChargingLocationInput;
};


/** Mutation root */
export type MutationUpdateCompaniesForDocumentArgs = {
  update?: InputMaybe<UpdateDocumentCompaniesInput>;
};


/** Mutation root */
export type MutationUpdateDeviceNameArgs = {
  deviceId: Scalars['String'];
  deviceName: Scalars['String'];
};


/** Mutation root */
export type MutationUpdateDeviceOwnerArgs = {
  deviceId: Scalars['String'];
  deviceOwner: DeviceOwnerInput;
};


/** Mutation root */
export type MutationUpdateEstimatedHourlyActiveEnergyLimitWarningAlertSettingArgs = {
  input: UpdateEstimatedHourlyActiveEnergyLimitWarningAlertSettingInput;
};


/** Mutation root */
export type MutationUpdateHourlyConsumptionLimitEstimationWarningAlertSettingArgs = {
  input: UpdateHourlyConsumptionLimitEstimationWarningAlertSettingInput;
};


/** Mutation root */
export type MutationUpdateSmartChargingPolicyArgs = {
  input: UpdateSmartChargingPolicyInput;
};


/** Mutation root */
export type MutationUpdateSustainableConsumptionAlertSettingArgs = {
  input: UpdateSustainableConsumptionAlertSettingInput;
};


/** Mutation root */
export type MutationValiderEpostArgs = {
  epost: Scalars['String'];
  kode: Scalars['String'];
};

export type MyDevicesInput = {
  filter?: InputMaybe<DeviceFilter>;
};

export enum NordpoolArea {
  NO1 = 'NO1',
  NO2 = 'NO2',
  NO3 = 'NO3',
  NO4 = 'NO4',
  NO5 = 'NO5'
}

export type NotConfiguredIntegrationVendorInfo = {
  status: IntegrationVendorStatus;
  vendor: IntegrationVendor;
};

export type NotificationChannel = BrightNotificationChannel | IntermediaryWebhookNotificationChannel | PushNotificationChannel;

export enum NotificationChannelType {
  BRIGHT_NOTIFICATION = 'BRIGHT_NOTIFICATION',
  INTERMEDIARY_WEBHOOK = 'INTERMEDIARY_WEBHOOK',
  PUSH_NOTIFICATION = 'PUSH_NOTIFICATION'
}

export enum NotificationType {
  PRICE_WARNING = 'PRICE_WARNING'
}

export type Nyetablering = {
  bedrift: Scalars['String'];
  epost: Scalars['String'];
  kundenummer?: Maybe<Scalars['Int']>;
  orgnummer: Scalars['ID'];
  telefon?: Maybe<Scalars['String']>;
};

export type NyetableringInput = {
  bedrift: Scalars['String'];
  epost: Scalars['String'];
  kundenummer?: InputMaybe<Scalars['Int']>;
  orgnummer: Scalars['ID'];
  telefon?: InputMaybe<Scalars['String']>;
};

export enum Operator {
  GT = 'GT',
  LT = 'LT'
}

export type OppdaterBedriftInput = {
  kontaktEpost: Scalars['String'];
  kontaktperson: Scalars['String'];
  navn: Scalars['String'];
  orgnummer: Scalars['ID'];
};

export enum Opploesning {
  ar = 'ar',
  dag = 'dag',
  dagsnittAr = 'dagsnittAr',
  dagsnittManed = 'dagsnittManed',
  maned = 'maned',
  time = 'time',
  timesnittAr = 'timesnittAr',
  timesnittManed = 'timesnittManed',
  timesnittUke = 'timesnittUke',
  uke = 'uke'
}

export type OpprettBedriftInput = {
  kontaktEpost: Scalars['String'];
  kontaktperson: Scalars['String'];
  moduler?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  navn: Scalars['String'];
  orgnummer: Scalars['ID'];
  sendInvitasjon?: InputMaybe<Scalars['Boolean']>;
};

export type OpprettInvitasjonInput = {
  epost: Scalars['String'];
  /** Navn på moduler */
  moduler: Array<InputMaybe<Scalars['String']>>;
};

export type OptionalFieldsPostalAddress = {
  country?: Maybe<PostcodeCountry>;
  postcode?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
};

export type OptionalFieldsPostalAddressInput = {
  country?: InputMaybe<PostcodeCountry>;
  postcode?: InputMaybe<Scalars['String']>;
  streetAddress?: InputMaybe<Scalars['String']>;
};

export type Order = {
  deviceId?: Maybe<Scalars['String']>;
  intermediaryId: Scalars['String'];
  orderId: Scalars['String'];
  orderStatus: OrderStatus;
  recipient: PostalRecipient;
  subscriptionType: OrderSubscriptionType;
  trackingLink?: Maybe<Scalars['String']>;
  type: DeviceType;
  updatedAt: Scalars['Date'];
  webhookCallbackUrl?: Maybe<Scalars['String']>;
};

export type OrderDeviceInput = {
  /** The person to whom the device should be shipped */
  recipient: PostalRecipientInput;
  subscriptionType: OrderSubscriptionType;
  type: DeviceType;
  /** The URL to which the webhook should be sent when the order status is changed */
  webhookCallbackUrl?: InputMaybe<Scalars['String']>;
};

export type OrderDevicePayload = {
  order: Order;
  ordersForUser: Array<Maybe<Order>>;
};

export type OrderInput = {
  orderId: Scalars['String'];
};

export enum OrderStatus {
  CANCELLED = 'CANCELLED',
  IN_PROGRESS = 'IN_PROGRESS',
  ORDERED = 'ORDERED',
  SHIPPED = 'SHIPPED'
}

export enum OrderSubscriptionType {
  BUSINESS = 'BUSINESS',
  PERSONAL = 'PERSONAL'
}

export type OrderSummary = {
  intermediaryId: Scalars['String'];
  ltemsToBeShipped: Scalars['Int'];
  wifisToBeShipped: Scalars['Int'];
};

export type OrderSummaryPayload = {
  orderSummary: Array<Maybe<OrderSummary>>;
};

export type OrdersForUserInput = {
  externalUserId: Scalars['String'];
};

export type OrdersForUserPayload = {
  orders: Array<Order>;
};

/** User information for device owners */
export type OwnedByUserInfo = {
  emailAddress?: Maybe<Scalars['String']>;
  externalUserId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  postalAddress?: Maybe<OptionalFieldsPostalAddress>;
};

/** The fixed part is given as price per month */
export type PerMonthFixedFee = {
  pricePerMonth: PriceSpecification;
  yearlyEnergyFund: PriceSpecification;
};

/**
 * The fixed part divides a yearly price by days of the year and multiplies this
 * by days of the month
 */
export type PerYearDividedByDaysFixedFee = {
  pricePerYear: PriceSpecification;
  yearlyEnergyFund: PriceSpecification;
};

/** Our permission system works across different types of resources and different types of actors */
export type Permission = {
  /** The id of the entity being permitted, e.g. a userId */
  entityId: Scalars['String'];
  /** The id of the resource the permission is targeting, e.g. a deviceId */
  resourceId: Scalars['String'];
  /** Permissions to resources, like read or write, are defined by scopes */
  scopes: Array<Scalars['String']>;
};

export type PhasePrices = {
  /** Prices for 230V networks */
  voltage230: PhaseVoltagePrices;
  /** Prices for 400V networks */
  voltage400: PhaseVoltagePrices;
};

export type PhaseVoltagePrices = {
  currentRangePrices: Array<CurrentRangePrice>;
  yearlyPriceAboveCurrentRanges: PriceSpecification;
};

export type PlannedSmartChargingStatus = {
  externalStart: Scalars['Boolean'];
  plan: SmartChargingPlan;
  planningStatus: SmartChargingPlanningStatusType;
  type: SmartChargingStatusType;
};

export type PostalAddress = {
  country: PostcodeCountry;
  postcode: Scalars['String'];
  streetAddress: Scalars['String'];
};

export type PostalAddressInput = {
  country: PostcodeCountry;
  postcode: Scalars['String'];
  streetAddress: Scalars['String'];
};

export type PostalRecipient = {
  email: Scalars['String'];
  externalUserId: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  postalAddress: PostalRecipientPostalAddress;
};

export type PostalRecipientInput = {
  /** Max-length 60 characters */
  email: Scalars['String'];
  externalUserId: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  postalAddress: PostalRecipientPostalAddressInput;
};

export type PostalRecipientPostalAddress = {
  addressLine1: Scalars['String'];
  addressLine2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  country: CountryCode;
  postalCode: Scalars['String'];
};

export type PostalRecipientPostalAddressInput = {
  /** Max-length 35 characters */
  addressLine1: Scalars['String'];
  /** Max-length 35 characters */
  addressLine2?: InputMaybe<Scalars['String']>;
  /** Max-length 35 characters */
  city: Scalars['String'];
  country: CountryCode;
  /** 4 digits if country is Norway */
  postalCode: Scalars['String'];
};

export enum PostcodeCountry {
  NORWAY = 'NORWAY'
}

export type PowerRangeInKw = {
  /** From, inclusive */
  from: Scalars['Int'];
  /** Until, exclusive */
  until: Scalars['Int'];
};

export type PriceSpecification = {
  /** The actual price, this should be including VAT (MVA) where it applies */
  price: Scalars['Float'];
  /** The currency of the given price, usually in NOK */
  priceCurrency: Currency;
  /** The prices without VAT (MVA) */
  priceExcludingVat: Scalars['Float'];
  /** VAT, or "Merverdiavgift" */
  vat: Scalars['Float'];
};

export type PriceWarningAlertSetting = {
  alertType: AlertType;
  createdBy: AlertSettingCreator;
  energyBiddingZone: EnergyBiddingZone;
  id: Scalars['ID'];
  notificationChannels: Array<NotificationChannel>;
};

/** An event type that is used if PriceWarningAlertSetting was triggered. */
export type PriceWarningEvent = {
  alertSettingId: Scalars['String'];
  alertType: SentAlertAlertType;
  at: Scalars['Date'];
  energyBiddingZone: EnergyBiddingZone;
  intermediaryId?: Maybe<Scalars['String']>;
  notificationChannelType: NotificationChannelType;
  type: AlertEventType;
  userId?: Maybe<Scalars['String']>;
};

export type PriceWarningPushNotificationSetting = {
  deviceId: Scalars['String'];
  notificationType: NotificationType;
  wantsNotification: Scalars['Boolean'];
};

export type PriceZone = {
  zone: EnergyBiddingZone;
};

export type PriceZoneInput = {
  addressInput?: InputMaybe<AddressInput>;
  latLngInput?: InputMaybe<LatLngInput>;
};

/** Prices and taxes type */
export type PricesAndTaxes = {
  priceExcludingEnergyTaxes: PriceSpecification;
  priceIncludingEnergyTaxes: PriceSpecification;
  taxes: DeliveryChargeEnergyTaxes;
};

/** Prices and taxes for business */
export type PricesAndTaxesBusiness = {
  priceExcludingEnergyTaxes: PriceSpecification;
  priceIncludingEnergyTaxes: PriceSpecification;
  taxes: DeliveryChargeEnergyTaxesBusiness;
};

/**
 * Price and tax info for part of a day, includes a specification of which hours
 * the price is for.
 */
export type PricesAndTaxesForPartOfDay = {
  hours: HourRange;
  pricesAndTaxes: PricesAndTaxes;
};

/**
 * Price and tax info for part of a day, includes a specification of which hours
 * the price is for.
 */
export type PricesAndTaxesForPartOfDayBusiness = {
  hours: HourRange;
  pricesAndTaxes: PricesAndTaxesBusiness;
};

/**
 * The prices for a part of year, will include the price specifiations and a
 * for what months the prices is for
 */
export type PricesForPartOfYear = {
  months: MonthRange;
  prices: DayAndNightPrices;
};

/**
 * The prices for a part of year, will include the price specifiations and a
 * for what months the prices is for
 */
export type PricesForPartOfYearBusiness = {
  months: MonthRange;
  prices: DayAndNightPricesBusiness;
};

/**
 * The prices for a part of year without differentiating day and night, will include the price specifications and a
 * for what months the prices is for
 */
export type PricesForPartOfYearWithoutDayAndNight = {
  months: MonthRange;
  /** @deprecated The field is not complete, use the adjacent field `pricesAndTaxes` */
  price: PriceSpecification;
  pricesAndTaxes: PricesAndTaxes;
};

/**
 * The prices for a part of year without differentiating day and night, will include the price specifiations and a
 * for what months the prices is for
 */
export type PricesForPartOfYearWithoutDayAndNightBusiness = {
  months: MonthRange;
  pricesAndTaxes: PricesAndTaxesBusiness;
};

export type ProcessOrderForIntermediaryInput = {
  deviceType: DeviceType;
  intermediaryId: Scalars['String'];
};

export type ProcessOrderForIntermediaryPayload = {
  order?: Maybe<Order>;
};

export type PushNotificationChannel = {
  forUserWithId: Scalars['String'];
  type: NotificationChannelType;
};

export type PushNotificationSetting = PriceWarningPushNotificationSetting;

/** Query root */
export type Query = {
  _placeholder: Scalars['String'];
  currentIntermediary: CurrentIntermediaryPayload;
  /**
   * Day ahead prices
   *
   * Get price data from the Entso-E transparency platrom for the time range
   * specified.
   *
   * Prices are converted to NOK and VAT is added for the zones where
   * this is applicable.
   *
   * Read more about the transparency platform of the European Network of
   * Transmission System Operators at https://transparency.entsoe.eu/
   */
  dayAheadPrices: Array<Maybe<EnergyPriceOfHour>>;
  deliveryChargeArea?: Maybe<DeliveryChargeArea>;
  deliveryChargeCost: DeliveryChargeCost;
  deliveryChargeCostBusiness: DeliveryChargeCostBusiness;
  deliveryChargeCostEnterprise: DeliveryChargeCostEnterprise;
  /** Get the delivery charge model for a DSO in the private segment */
  deliveryChargeModel: DeliveryChargeModel;
  /** Get the delivery charge model for a DSO in the business segment */
  deliveryChargeModelBusiness: DeliveryChargeModelBusiness;
  /** Get the delivery charge model for a DSO in the enterprise/industry segment */
  deliveryChargeModelEnterprise: DeliveryChargeModelEnterprise;
  device: DeviceQueryPayload;
  electricalPowerTaxes: ElectricalPowerTaxes;
  energyFundTaxes: EnergyFundTaxes;
  finnBrukere?: Maybe<Array<Maybe<Bruker>>>;
  getAllCompanyDocuments?: Maybe<CompanyDocuments>;
  getDistributedDocument?: Maybe<DistributedDocument>;
  getDistributedDocuments?: Maybe<Array<Maybe<DistributedDocument>>>;
  hentAlleInvitasjoner?: Maybe<Array<Maybe<Invitasjon>>>;
  hentBedrift?: Maybe<Bedrift>;
  hentBedriftFraKundesystem?: Maybe<KundesystemBedrift>;
  hentBedrifter?: Maybe<Array<Maybe<Bedrift>>>;
  /** Henter brukerprofil til autentisert bruker. Dersom den ikke finnes i databasen fra før blir den opprettet. Ved å angi <tt>orgnummer</tt> vil man filtrere målerpunktgruppene slik at bare de gruppene som er knyttet til den angitte organisasjonen blir returnert. Om <tt>orgnummer</tt> ikke angis vil hele brukerprofilen returneres. */
  hentBrukerprofil?: Maybe<Brukerprofil>;
  /** Hent anlegg fra Elhub. Brukes vanligvis med postnummer+maalernummer, eller maalepunktId */
  hentElhubAnlegg?: Maybe<ElhubAnlegg>;
  hentEntriesFraContentful?: Maybe<Array<Maybe<ContentfulEntry>>>;
  /** Hent alle forbruksvarsler for innlogget bruker i organisasjonen, med mulighet for å filtrere på anleggs- og deviceId */
  hentForbruksvarsler: Array<Forbruksvarsel>;
  hentInvitasjoner?: Maybe<Array<Maybe<Invitasjon>>>;
  hentInvitasjonsstatus?: Maybe<InvitasjonsstatusOgOrgNavn>;
  /** Henter navn for bedrift dersom kundeId er orgnummer, og navn på person dersom kundeId er personnummer */
  hentKundenavn?: Maybe<Scalars['String']>;
  hentKunderForBedriftFraKundesystem?: Maybe<Array<Maybe<KundesystemBedrift>>>;
  hentSpotpris?: Maybe<Array<Maybe<Spotpristime>>>;
  hentTariffer?: Maybe<Array<Maybe<BedriftsTariff>>>;
  hentTarifferStrombestilling?: Maybe<Array<Maybe<Tariff>>>;
  hentTilgangerForInnloggetBruker?: Maybe<Array<Maybe<Scalars['String']>>>;
  hentTilgjengeligeModuler?: Maybe<Array<Maybe<Scalars['String']>>>;
  innloggetBruker?: Maybe<InnloggetBruker>;
  /** Generates an authorization URL which can be embedded in a webview which in turn will allow an end user to grant our API access to control their vehicle/charger */
  integrationAuthorizationUrl: IntegrationAuthorizationUrlResponse;
  kanEtableres?: Maybe<Kunde>;
  /** Get Locations */
  locations: Array<Location>;
  maalere?: Maybe<Array<Maybe<Scalars['String']>>>;
  me: UserInfo;
  /**
   * Get data from the latest measurements using deviceId or meterId (or both)
   *
   * * If only deviceId is given, reports for the most recent meterId that the device was connected to in the requested range will be returned
   * * If only meterId is given, reports for this meter will be returned from the most recent deviceId that was connected to this meter in the requested range
   * * If both meterId and deviceId is given, reports will only be returned if the given deviceId was connected to the given meterId in the requested range
   *
   * In all cases the accessToken needs permissions to read reports for the corresponding deviceId.
   *
   * Measurements are only available for two to three hours.
   */
  measurements: Array<Measurement>;
  /** Get metadata about services, like minimum required app requiredAppVersion */
  metadata: Metadata;
  myDevices: Array<DeviceInfo>;
  /** Get an Order by its Id */
  order: Order;
  /** Get all Orders for a given user */
  ordersForUser: OrdersForUserPayload;
  /** Get permissions containing requested scopes for the authenticated user */
  permissions: Array<Permission>;
  priceZone?: Maybe<PriceZone>;
  /**
   * Get hourly or daily reports using deviceId or meterId (or both)
   *
   * * If only deviceId is given, reports for the most recent meterId that the device was connected to in the requested range will be returned
   * * If only meterId is given, reports for this meter will be returned from the most recent deviceId that was connected to this meter in the requested range
   * * If both meterId and deviceId is given, reports will only be returned if the given deviceId was connected to the given meterId in the requested range
   *
   * In all cases the accessToken needs permissions to read reports for the corresponding deviceId.
   */
  reports: Array<Report>;
  /** Sjekk om bedriften med orgnummer finnes i Bedriftsportalen eller Bisnode */
  slaaoppBedrift?: Maybe<Bedriftsoppslag>;
  /** Get anonymous tracking id for a user given national id number. */
  trackingId?: Maybe<TrackingId>;
  /** Get anonymous tracking id for current user. Will return an empty object if the user's national id number is null or empty. */
  trackingIdForUser?: Maybe<TrackingId>;
  user: UserPayload;
  /** Hent alle varslinger for innlogget bruker i organisasjonen */
  varslingerForBrukerIOrganisasjon?: Maybe<Array<Maybe<Varsling>>>;
};


/** Query root */
export type QueryDayAheadPricesArgs = {
  from: Scalars['Date'];
  to: Scalars['Date'];
  zone: EnergyBiddingZone;
};


/** Query root */
export type QueryDeliveryChargeAreaArgs = {
  input: DeliveryChargeAreaInput;
};


/** Query root */
export type QueryDeliveryChargeCostArgs = {
  input: DeliveryChargeCostInput;
};


/** Query root */
export type QueryDeliveryChargeCostBusinessArgs = {
  input: DeliveryChargeCostBusinessInput;
};


/** Query root */
export type QueryDeliveryChargeCostEnterpriseArgs = {
  input: DeliveryChargeCostEnterpriseInput;
};


/** Query root */
export type QueryDeliveryChargeModelArgs = {
  input: DeliveryChargeModelInput;
};


/** Query root */
export type QueryDeliveryChargeModelBusinessArgs = {
  input: DeliveryChargeModelBusinessInput;
};


/** Query root */
export type QueryDeliveryChargeModelEnterpriseArgs = {
  input: DeliveryChargeModelInput;
};


/** Query root */
export type QueryDeviceArgs = {
  input: DeviceQueryInput;
};


/** Query root */
export type QueryElectricalPowerTaxesArgs = {
  input: ElectricalPowerTaxesInput;
};


/** Query root */
export type QueryEnergyFundTaxesArgs = {
  input: EnergyFundTaxesInput;
};


/** Query root */
export type QueryFinnBrukereArgs = {
  query: Scalars['String'];
};


/** Query root */
export type QueryGetAllCompanyDocumentsArgs = {
  companyId: Scalars['ID'];
};


/** Query root */
export type QueryGetDistributedDocumentArgs = {
  id: Scalars['ID'];
};


/** Query root */
export type QueryGetDistributedDocumentsArgs = {
  pageSize?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};


/** Query root */
export type QueryHentAlleInvitasjonerArgs = {
  filter: Tilstand;
};


/** Query root */
export type QueryHentBedriftArgs = {
  orgid: Scalars['ID'];
};


/** Query root */
export type QueryHentBedriftFraKundesystemArgs = {
  inkluderAvviklede?: InputMaybe<Scalars['Boolean']>;
  orgnummer: Scalars['ID'];
};


/** Query root */
export type QueryHentBrukerprofilArgs = {
  orgnummer: Scalars['ID'];
};


/** Query root */
export type QueryHentElhubAnleggArgs = {
  maalepunktId?: InputMaybe<Scalars['String']>;
  maalernummer?: InputMaybe<Scalars['String']>;
  orgid: Scalars['ID'];
  postnummer?: InputMaybe<Scalars['String']>;
};


/** Query root */
export type QueryHentEntriesFraContentfulArgs = {
  contentType: Scalars['String'];
  gruppeId?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
};


/** Query root */
export type QueryHentForbruksvarslerArgs = {
  anleggsId?: InputMaybe<Scalars['String']>;
  deviceId?: InputMaybe<Scalars['String']>;
  orgId: Scalars['String'];
};


/** Query root */
export type QueryHentInvitasjonerArgs = {
  filter?: InputMaybe<Tilstand>;
  orgid: Scalars['ID'];
};


/** Query root */
export type QueryHentInvitasjonsstatusArgs = {
  invitasjonId: Scalars['ID'];
  token: Scalars['ID'];
};


/** Query root */
export type QueryHentKundenavnArgs = {
  kundeId: Scalars['ID'];
};


/** Query root */
export type QueryHentKunderForBedriftFraKundesystemArgs = {
  inkluderAvviklede?: InputMaybe<Scalars['Boolean']>;
  orgId: Scalars['ID'];
};


/** Query root */
export type QueryHentSpotprisArgs = {
  energisone?: InputMaybe<NordpoolArea>;
  fra?: InputMaybe<Scalars['DateTime']>;
  til?: InputMaybe<Scalars['DateTime']>;
};


/** Query root */
export type QueryHentTarifferArgs = {
  orgid: Scalars['ID'];
};


/** Query root */
export type QueryIntegrationAuthorizationUrlArgs = {
  input: IntegrationAuthorizationUrlInput;
};


/** Query root */
export type QueryKanEtableresArgs = {
  kundenummer?: InputMaybe<Scalars['Int']>;
  orgnummer: Scalars['ID'];
};


/** Query root */
export type QueryMeasurementsArgs = {
  deviceId?: InputMaybe<Scalars['String']>;
  from: Scalars['String'];
  meterId?: InputMaybe<Scalars['String']>;
  to: Scalars['String'];
};


/** Query root */
export type QueryMyDevicesArgs = {
  input?: InputMaybe<MyDevicesInput>;
};


/** Query root */
export type QueryOrderArgs = {
  input: OrderInput;
};


/** Query root */
export type QueryOrdersForUserArgs = {
  input: OrdersForUserInput;
};


/** Query root */
export type QueryPermissionsArgs = {
  scopes: Array<Scalars['String']>;
};


/** Query root */
export type QueryPriceZoneArgs = {
  input: PriceZoneInput;
};


/** Query root */
export type QueryReportsArgs = {
  deviceId?: InputMaybe<Scalars['String']>;
  from: Scalars['String'];
  interval: Interval;
  meterId?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Timezone>;
  to: Scalars['String'];
};


/** Query root */
export type QuerySlaaoppBedriftArgs = {
  orgnummer: Scalars['ID'];
};


/** Query root */
export type QueryTrackingIdArgs = {
  fodselsnummer: Scalars['String'];
};


/** Query root */
export type QueryUserArgs = {
  input: UserInput;
};


/** Query root */
export type QueryVarslingerForBrukerIOrganisasjonArgs = {
  orgId: Scalars['String'];
};

export type Range = {
  max: Scalars['Float'];
  mean: Scalars['Float'];
  min: Scalars['Float'];
};

export type RecentMeter = {
  firstSeenAt: Scalars['Date'];
  meterId: Scalars['MeterId'];
  producer: MeterProducer;
  shortMeterId: Scalars['String'];
};

export type RefurbishDeviceInput = {
  deviceId: Scalars['String'];
  parts: RefurbishDevicePartsInput;
  refurbishId?: InputMaybe<Scalars['String']>;
};

export type RefurbishDevicePartsInput = {
  backCasingOk: Scalars['Boolean'];
  boxDeviceLabelOk: Scalars['Boolean'];
  cardboardBoxOk: Scalars['Boolean'];
  deviceLabelOk: Scalars['Boolean'];
  deviceTypeStickerOk: Scalars['Boolean'];
  frontCasingOk: Scalars['Boolean'];
  manualOk: Scalars['Boolean'];
  pcbaOk: Scalars['Boolean'];
  rj45Ok: Scalars['Boolean'];
  sealOk: Scalars['Boolean'];
  usbOk?: InputMaybe<Scalars['Boolean']>;
};

export type RefurbishDevicePayload = {
  device: DeviceInfo;
};

/** Refurbish information for the device. Refurbishing happens after a device is returned. */
export type RefurbishInfo = {
  refurbishId: Scalars['String'];
  refurbishedAt: Scalars['Date'];
};

export type RegisterInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  redirectUri?: InputMaybe<Scalars['String']>;
};

export type RemoveChargingPermissionInput = {
  userIdentifier: UserIdentifierInput;
};

export type RemoveChargingPermissionPayload = {
  modifiedPermission: Permission;
  success: Scalars['Boolean'];
  user: UserInfo;
};

export type RemoveControllableFromLocationInput = {
  controllableId: Scalars['String'];
  locationId: Scalars['String'];
};

export type RemoveControllableFromLocationPayload = {
  location: Location;
};

export type RemoveDevicePermissionsInput = {
  deviceIdentifiers: Array<DeviceIdentifierInput>;
  userIdentifier: UserIdentifierInput;
};

export type RemoveDevicePermissionsPayload = {
  modifiedPermissions: Array<Permission>;
  success: Scalars['Boolean'];
  user: UserInfo;
};

export type RemoveIntermediaryApiKeyInput = {
  id: Scalars['String'];
};

export type RemoveIntermediaryUserInput = {
  externalId: Scalars['String'];
};

export type RemoveIntermediaryUserPayload = {
  user: UserInfo;
};

export type RemoveLocationInput = {
  locationId: Scalars['String'];
};

export type RemoveLocationPayload = {
  location: Location;
};

export type RemoveNotificationChannelFromAlertSettingInput = {
  alertSettingId: Scalars['ID'];
  notificationChannelType: NotificationChannelType;
};

export type RemoveNotificationChannelFromAlertSettingPayload = {
  success: Scalars['Boolean'];
};

export type RemoveUserInput = {
  userIdentifier: UserIdentifierInput;
};

export type RemoveUserPayload = {
  success: Scalars['Boolean'];
  user: UserInfo;
};

export type Report = {
  /**
   * Active energy exported in the time period, in Wh
   *
   * Not available for the _current hour_
   */
  activeEnergyExported?: Maybe<Scalars['Float']>;
  /**
   * Active energy imported in the time period, in Wh
   *
   * Not available for the _current hour_
   */
  activeEnergyImported?: Maybe<Scalars['Float']>;
  /** Average of ActivePowerExport values in the time period, in W */
  averageActivePowerExport?: Maybe<Scalars['Float']>;
  /** Average of ActivePowerImport values in the time period, in W */
  averageActivePowerImport?: Maybe<Scalars['Float']>;
  /** Cumulative values of the active energy export in Wh at the start of the interval */
  cumulativeActiveEnergyExportedAtIntervalStart?: Maybe<Scalars['Float']>;
  /** Cumulative values of the active energy import in Wh at the start of the interval */
  cumulativeActiveEnergyImportedAtIntervalStart?: Maybe<Scalars['Float']>;
  /** Cumulative values of the reactive energy export in varh at the start of the interval */
  cumulativeReactiveEnergyExportedAtIntervalStart?: Maybe<Scalars['Float']>;
  /** Cumulative values of the reactive energy import in varh at the start of the interval */
  cumulativeReactiveEnergyImportedAtIntervalStart?: Maybe<Scalars['Float']>;
  /** Report start time, in UTC */
  from: Scalars['Date'];
  /** Report end time, in UTC */
  to: Scalars['Date'];
};

export enum Retning {
  forbruk = 'forbruk',
  produksjon = 'produksjon'
}

export type ReturnDeviceInput = {
  comment?: InputMaybe<Scalars['String']>;
  deviceId: Scalars['String'];
  hasBeenUsed: Scalars['Boolean'];
};

export type ReturnDevicePayload = {
  device: DeviceInfo;
  success: Scalars['Boolean'];
};

/** Return information */
export type ReturnInfo = {
  comment?: Maybe<Scalars['String']>;
  hasBeenUsed?: Maybe<Scalars['Boolean']>;
  returnedAt?: Maybe<Scalars['Date']>;
};

export type RevokeDeviceAccessInput = {
  deviceId: Scalars['String'];
  userId: Scalars['String'];
};

export type RushPricingDay = {
  hours: HourRange;
  price: PriceSpecification;
};

/** The additional rush prices in a list, between two dates */
export type RushPricingPeriod = {
  dateRange: DateRange;
  days: RushPricingWeek;
  holidaysIncluded: Scalars['Boolean'];
};

export type RushPricingWeek = {
  friday?: Maybe<RushPricingDay>;
  monday?: Maybe<RushPricingDay>;
  saturday?: Maybe<RushPricingDay>;
  sunday?: Maybe<RushPricingDay>;
  thursday?: Maybe<RushPricingDay>;
  tuesday?: Maybe<RushPricingDay>;
  wednesday?: Maybe<RushPricingDay>;
};

export type Salgsordre = {
  abonnementgruppe?: Maybe<Scalars['String']>;
  /** ISO-8601 */
  avregnetTil?: Maybe<Scalars['Date']>;
  beskrivelse?: Maybe<Scalars['String']>;
  fakturamerke?: Maybe<Scalars['String']>;
  forbrukskode?: Maybe<Scalars['String']>;
  /** ISO-8601 */
  fraDato?: Maybe<Scalars['Date']>;
  salgsordrenummer?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  tariffVersjoner?: Maybe<Array<Maybe<TariffVersjon>>>;
  /** ISO-8601 */
  tilDato?: Maybe<Scalars['Date']>;
  virksomhet?: Maybe<Scalars['String']>;
};

export type Search = {
  deviceId?: InputMaybe<Scalars['String']>;
  externalUserId?: InputMaybe<Scalars['String']>;
  meterId?: InputMaybe<Scalars['String']>;
  query?: InputMaybe<Scalars['String']>;
};

/** There are different prices for summer and winter, day and night */
export type SeasonalPrices = {
  summer: PricesForPartOfYear;
  winter: PricesForPartOfYear;
};

/** There are different prices for summer and winter, day and night */
export type SeasonalPricesBusiness = {
  summer: PricesForPartOfYearBusiness;
  winter: PricesForPartOfYearBusiness;
};

export type SeasonalPricesDemandTariff = {
  /**
   * Only used for a couple of companies, which will skip counting night/weekends
   * when finding the peak hour(s) of the month
   */
  highDemandHoursForWorkDays?: Maybe<HourRange>;
  peaksPerMonth: Scalars['Int'];
  summer: SeasonalPricesDemandTariffSeason;
  winter: SeasonalPricesDemandTariffSeason;
};

export type SeasonalPricesDemandTariffSeason = {
  months: MonthRange;
  pricePerKw: PriceSpecification;
};

/** There are different prices for summer and winter, but not day and night */
export type SeasonalPricesWithoutDayAndNight = {
  summer: PricesForPartOfYearWithoutDayAndNight;
  winter: PricesForPartOfYearWithoutDayAndNight;
};

/** There are different prices for summer and winter, but not day and night */
export type SeasonalPricesWithoutDayAndNightBusiness = {
  summer: PricesForPartOfYearWithoutDayAndNightBusiness;
  winter: PricesForPartOfYearWithoutDayAndNightBusiness;
};

export type SeasonalTieredDemandTariff = {
  /**
   * Only used for a couple of companies, which will skip counting night/weekends
   * when finding the peak hour(s) of the month
   */
  highDemandHoursForWorkDays?: Maybe<HourRange>;
  peaksPerMonth: Scalars['Int'];
  summer: TieredSeason;
  winter: TieredSeason;
};

/**
 * Energy model were users can select type of energy model.
 *
 * Companies might have multiple types of energy models, and
 * let users select which model they would like use.
 */
export type SelectableEnergyModel = {
  constantPrice?: Maybe<ConstantPrice>;
  differentPricesDayAndNight?: Maybe<DifferentPricesDayAndNight>;
  differentPricesSeasonal?: Maybe<DifferentPricesSeasonal>;
  differentPricesSeasonalDayAndNight?: Maybe<DifferentPricesSeasonalDayAndNight>;
  type: EnergyModelType;
};

/**
 * Energy model were users can select type of energy model.
 *
 * Companies might have multiple types of energy models, and
 * let users select which model they would like use.
 */
export type SelectableEnergyModelBusiness = {
  constantPrice?: Maybe<ConstantPriceBusiness>;
  demandChargeStepWithSeasons?: Maybe<DemandChargeStepWithSeasonsBusiness>;
  differentPricesSeasonal?: Maybe<DifferentPricesSeasonalBusiness>;
  type: EnergyModelBusinessType;
};

export type SelectablePhasePrices = {
  voltage230: SelectablePhaseVoltagePrices;
  voltage400: SelectablePhaseVoltagePrices;
};

export type SelectablePhasePricesBusiness = {
  voltage230: SelectablePhaseVoltagePricesBusiness;
  voltage400: SelectablePhaseVoltagePricesBusiness;
};

export type SelectablePhaseVoltagePrices = {
  currentRangesWithSelectable: Array<CurrentRangeWithSelectable>;
};

export type SelectablePhaseVoltagePricesBusiness = {
  currentRangesWithSelectable: Array<CurrentRangeWithSelectableBusiness>;
  energyModelAboveCurrentRanges?: Maybe<SelectableEnergyModelBusiness>;
};

export type SelectableSeasonal = {
  regular: PricesAndTaxes;
  seasonal: EnergyModelSeasonalConstant;
};

export enum SentAlertAlertType {
  ESTIMATED_HOURLY_ACTIVE_ENERGY_LIMIT_WARNING = 'ESTIMATED_HOURLY_ACTIVE_ENERGY_LIMIT_WARNING',
  HOURLY_CONSUMPTION_LIMIT_ESTIMATION_WARNING = 'HOURLY_CONSUMPTION_LIMIT_ESTIMATION_WARNING',
  HOURLY_CONSUMPTION_SUSTAINABLE_ESTIMATION_MESSAGE = 'HOURLY_CONSUMPTION_SUSTAINABLE_ESTIMATION_MESSAGE',
  PRICE_WARNING = 'PRICE_WARNING',
  SUSTAINABLE_CONSUMPTION_WEBHOOK = 'SUSTAINABLE_CONSUMPTION_WEBHOOK'
}

export type SessionInfo = {
  accessToken: Scalars['String'];
  userId: Scalars['String'];
};

export type SessionInfoWithoutAccessToken = {
  startOfSecret: Scalars['String'];
  userId: Scalars['String'];
};

export type SetCoordinatesForLocationInput = {
  coordinates: LatLngInput;
  locationId: Scalars['String'];
};

export type SetCoordinatesForLocationPayload = {
  location: Location;
};

export type SetDeviceActivationStatusInput = {
  activationStatus: ActivationStatus;
  deviceIdentifier: DeviceIdentifierInput;
};

export type SetDeviceActivationStatusPayload = {
  device: DeviceInfo;
  success: Scalars['Boolean'];
};

export type SetDeviceForLocationInput = {
  deviceIdentifier: DeviceQueryIdentifier;
  locationId: Scalars['String'];
};

export type SetDeviceForLocationPayload = {
  location: Location;
};

export type SetEnergyConsumptionLimitForLocationInput = {
  limit: Scalars['Int'];
  locationId: Scalars['String'];
};

export type SetEnergyConsumptionLimitForLocationPayload = {
  location: Location;
};

export type SetIntermediaryWebhookSettingInput = {
  intermediaryId?: InputMaybe<Scalars['String']>;
  secret: Scalars['String'];
};

export type SetIntermediaryWebhookSettingPayload = {
  webhookSetting: IntermediaryWebhookSetting;
};

export type SetNameForLocationInput = {
  locationId: Scalars['String'];
  name: Scalars['String'];
};

export type SetNameForLocationPayload = {
  location: Location;
};

export type ShipDeviceIdentifierInput = {
  claimId?: InputMaybe<Scalars['ClaimId']>;
  deviceId?: InputMaybe<Scalars['DeviceId']>;
};

export type ShipOrderInput = {
  deviceIdentifier: ShipDeviceIdentifierInput;
  orderId: Scalars['String'];
  trackingLink: Scalars['String'];
};

export type ShipToUserInput = {
  emailAddress?: InputMaybe<Scalars['String']>;
  externalUserId?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  postalAddress: PostalAddressInput;
  trackingLink?: InputMaybe<Scalars['String']>;
};

/** User information for tracking shipment and activation */
export type ShippedToUserInfo = {
  emailAddress?: Maybe<Scalars['String']>;
  externalUserId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  postalAddress: PostalAddress;
  trackingLink?: Maybe<Scalars['String']>;
};

export type SmartChargingConsideration = {
  hasTimeEstimate: Scalars['Boolean'];
  isCharging: Scalars['Boolean'];
  isPluggedIn: Scalars['Boolean'];
  recentlyAtChargingLocation: Scalars['Boolean'];
};

export type SmartChargingPlan = {
  endedAt?: Maybe<Scalars['String']>;
  estimatedFinishAt: Scalars['String'];
  nonSmartCost: Scalars['Float'];
  smartCost?: Maybe<Scalars['Float']>;
  startAt?: Maybe<Scalars['String']>;
  startConfirmedAt?: Maybe<Scalars['String']>;
  stopConfirmedAt?: Maybe<Scalars['String']>;
};

export enum SmartChargingPlanningStatusType {
  PLAN_ENDED_DEADLINE_CHANGED = 'PLAN_ENDED_DEADLINE_CHANGED',
  PLAN_ENDED_DISABLED = 'PLAN_ENDED_DISABLED',
  PLAN_ENDED_FAILED = 'PLAN_ENDED_FAILED',
  PLAN_ENDED_FINISHED = 'PLAN_ENDED_FINISHED',
  PLAN_ENDED_UNPLUGGED = 'PLAN_ENDED_UNPLUGGED',
  PLAN_EXECUTING_CHARGE_INTERRUPTED = 'PLAN_EXECUTING_CHARGE_INTERRUPTED',
  PLAN_EXECUTING_OVERRIDDEN = 'PLAN_EXECUTING_OVERRIDDEN',
  PLAN_EXECUTING_STARTED = 'PLAN_EXECUTING_STARTED',
  PLAN_EXECUTING_STARTING = 'PLAN_EXECUTING_STARTING',
  PLAN_EXECUTING_START_FAILED = 'PLAN_EXECUTING_START_FAILED',
  PLAN_EXECUTING_STOPPED = 'PLAN_EXECUTING_STOPPED',
  PLAN_EXECUTING_STOPPED_AWAITING_PRICES = 'PLAN_EXECUTING_STOPPED_AWAITING_PRICES',
  PLAN_EXECUTING_STOPPING = 'PLAN_EXECUTING_STOPPING',
  PLAN_EXECUTING_STOP_FAILED = 'PLAN_EXECUTING_STOP_FAILED'
}

export type SmartChargingPolicy = {
  deadlineInUtcTime: Scalars['String'];
  isEnabled: Scalars['Boolean'];
};

export enum SmartChargingStatusType {
  CONSIDERING = 'CONSIDERING',
  DISABLED = 'DISABLED',
  FULLY_CHARGED = 'FULLY_CHARGED',
  PLANNED = 'PLANNED'
}

export enum Source {
  IS_CUSTOMER = 'IS_CUSTOMER',
  UTILITYCLOUD = 'UTILITYCLOUD'
}

export type Spotpristime = {
  /** ISO-8601 */
  fra?: Maybe<Scalars['DateTime']>;
  pris: Scalars['Float'];
  /** ISO-8601 */
  til?: Maybe<Scalars['DateTime']>;
};

export type StartOperationalCheckInput = {
  deviceIdentifier: DeviceIdentifierInput;
};

export type StartOperationalCheckPayload = {
  device: DeviceInfo;
  success: Scalars['Boolean'];
};

export enum Status {
  FEIL_KODE = 'FEIL_KODE',
  IKKE_FUNNET = 'IKKE_FUNNET',
  OK = 'OK'
}

/**
 * Subscriptions use websocket, and our websocket service responds at the current URL + `/subscriptions`
 *
 * We support both `graphql-ws` and `graphql-transport-ws` protocols.
 */
export type Subscription = {
  /** Subscribe to realtime measurements of a device. */
  realtimeMeasurement?: Maybe<Measurement>;
  /** Subscribe to realtime measurements of all devices you have access to. */
  realtimeMeasurementAll?: Maybe<Measurement>;
};


/**
 * Subscriptions use websocket, and our websocket service responds at the current URL + `/subscriptions`
 *
 * We support both `graphql-ws` and `graphql-transport-ws` protocols.
 */
export type SubscriptionRealtimeMeasurementArgs = {
  deviceId?: InputMaybe<Scalars['String']>;
  meterId?: InputMaybe<Scalars['String']>;
};


/**
 * Subscriptions use websocket, and our websocket service responds at the current URL + `/subscriptions`
 *
 * We support both `graphql-ws` and `graphql-transport-ws` protocols.
 */
export type SubscriptionRealtimeMeasurementAllArgs = {
  input?: InputMaybe<Scalars['Void']>;
};

export type SubscriptionBilling = {
  subscriptionType: SubscriptionType;
};

export type SubscriptionInput = {
  subscriptionType?: InputMaybe<SubscriptionType>;
};

export enum SubscriptionType {
  BUSINESS = 'BUSINESS',
  PERSONAL = 'PERSONAL',
  PILOT = 'PILOT'
}

/** An alert that continuously evaluates whether the device has crossed a set limit for energy consumption within the current hour. */
export type SustainableConsumptionAlertSetting = {
  alertType: AlertType;
  createdBy: AlertSettingCreator;
  deviceId: Scalars['String'];
  id: Scalars['ID'];
  limitInWattHours: Scalars['Int'];
  notificationChannels: Array<NotificationChannel>;
};

/** An event type that describes the SustainableConsumptionEvent event. */
export type SustainableConsumptionEvent = {
  alertSettingId: Scalars['String'];
  alertType: SentAlertAlertType;
  appliesToHour: Scalars['Date'];
  at: Scalars['Date'];
  consumed: Energy;
  deviceId: Scalars['String'];
  forecasted: Energy;
  hourlyLimit: Energy;
  hourlyTotal: Energy;
  intermediaryId?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  notificationChannelType: NotificationChannelType;
  type: AlertEventType;
};

export type Tariff = {
  alias?: Maybe<Array<Maybe<Scalars['String']>>>;
  beskrivelse?: Maybe<Scalars['String']>;
  fakturagruppe?: Maybe<Scalars['String']>;
  iscu?: Maybe<Scalars['String']>;
  iscuKode?: Maybe<Scalars['String']>;
  manuell: Scalars['Boolean'];
  navn?: Maybe<Scalars['String']>;
  regler?: Maybe<Array<Maybe<Scalars['String']>>>;
  tariffId?: Maybe<Scalars['ID']>;
  tarifftype?: Maybe<Tarifftype>;
};

export type TariffVersjon = {
  aarsforbruk?: Maybe<Scalars['Float']>;
  aarskostnad?: Maybe<Scalars['Float']>;
  /** ISO-8601 */
  fradato?: Maybe<Scalars['Date']>;
  produkt?: Maybe<Scalars['String']>;
  tariff?: Maybe<Scalars['String']>;
  /** ISO-8601 */
  tildato?: Maybe<Scalars['Date']>;
};

export enum Tarifftype {
  Hovedprodukt = 'Hovedprodukt',
  Standardkontrakt = 'Standardkontrakt',
  Standardprodukt = 'Standardprodukt',
  Tariff = 'Tariff',
  Tilleggsprodukt = 'Tilleggsprodukt'
}

export type TestWebhookSettingInput = {
  callbackUrl: Scalars['String'];
  intermediaryId?: InputMaybe<Scalars['String']>;
};

export type TestWebhookSettingPayload = {
  success: Scalars['Boolean'];
};

export type TieredDemandTariff = {
  demandTariffTiers: Array<DemandTariffTier>;
  /**
   * Only used for a couple of companies, which will skip counting night/weekends
   * when finding the peak hour(s) of the month
   */
  highDemandHoursForWorkDays?: Maybe<HourRange>;
  peaksPerMonth: Scalars['Int'];
  priceAboveDemandTariffTiers: PriceSpecification;
};

export type TieredSeason = {
  demandTariffTiers: Array<DemandTariffTier>;
  months: MonthRange;
  priceAboveDemandTariffTiers: PriceSpecification;
};

export enum Tilstand {
  Akseptert = 'Akseptert',
  Ubesvart = 'Ubesvart'
}

/** Time zones, as reported by the _Internet Assigned Numbers Authority_ (IANA) database, but using an underscore for the forward slash. */
export enum Timezone {
  EUROPE_OSLO = 'EUROPE_OSLO',
  UTC = 'UTC'
}

export type TrackingId = {
  trackingId?: Maybe<Scalars['String']>;
};

export type UcInvoiceLine = {
  amount?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  elCert?: Maybe<Scalars['String']>;
  periode?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  vatCode?: Maybe<Scalars['String']>;
  vatRate?: Maybe<Scalars['String']>;
};

export type UcInvoicePayment = {
  amount?: Maybe<Scalars['Float']>;
  /** ISO-8601 */
  date?: Maybe<Scalars['DateTime']>;
  reference?: Maybe<Scalars['String']>;
};

export enum UcInvoicePaymentAgreementStatus {
  ACTIVE = 'ACTIVE',
  COMPLETED = 'COMPLETED',
  CREATED = 'CREATED',
  DELETED = 'DELETED',
  UNCOMPLETED = 'UNCOMPLETED'
}

/**
 * Input object for updating charge settings.
 * - `defaultStartBatteryLevel` can be set as a default battery level for the beginning of a session, if we cannot get the SoC from the vehicle this value will be used.
 * - `departureTimes` is a list of departure times for each day of the week.
 * - `desiredBatteryLevel` is the battery level at which the car should be charged at the departure time.
 * - `directChargingBatteryLevel` is the battery level at which the car should start charging immediately.
 * - `enableSmartChargingAt` is the date at which smart charging should be enabled.
 * - `isSmartChargingEnabled` is whether smart charging is enabled.
 * - `isSolarChargingEnabled` is whether solar charging is enabled.
 * - `maxAllowedTargetSoC` is the maximum allowed target state of charge.
 * - `timeZone` is the time zone of the user.
 * - `vehicleId` is the id of the vehicle.
 */
export type UpdateChargeSettingsInput = {
  defaultStartBatteryLevel?: InputMaybe<Scalars['Float']>;
  departureTimes: DepartureTimesInput;
  desiredBatteryLevel: Scalars['Float'];
  directChargingBatteryLevel: Scalars['Float'];
  enableSmartChargingAt?: InputMaybe<Scalars['Date']>;
  id: Scalars['String'];
  isSmartChargingEnabled: Scalars['Boolean'];
  isSolarChargingEnabled: Scalars['Boolean'];
  maxAllowedTargetSoC: Scalars['Float'];
  timeZone: Timezone;
  vehicleId: Scalars['String'];
};

/** Response object when updating charge settings. */
export type UpdateChargeSettingsPayload = {
  chargeSettings: ChargeSettings;
};

export type UpdateChargingLocationInput = {
  id: Scalars['ID'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  name: Scalars['String'];
};

export type UpdateChargingLocationPayload = {
  chargingLocation: ChargingLocation;
};

export type UpdateDocumentCompaniesInput = {
  companies: Array<InputMaybe<CompanyInput>>;
  documentId: Scalars['ID'];
};

export type UpdateEstimatedHourlyActiveEnergyLimitWarningAlertSettingInput = {
  alertSettingId: Scalars['ID'];
  limitInWattHours: Scalars['Int'];
};

export type UpdateEstimatedHourlyActiveEnergyLimitWarningAlertSettingPayload = {
  alertSetting: EstimatedHourlyActiveEnergyLimitWarningAlertSetting;
};

export type UpdateHourlyConsumptionLimitEstimationWarningAlertSettingInput = {
  alertSettingId: Scalars['ID'];
  limitInWatts: Scalars['Int'];
};

export type UpdateHourlyConsumptionLimitEstimationWarningAlertSettingPayload = {
  alertSetting: HourlyConsumptionLimitEstimationWarningAlertSetting;
};

export type UpdateJedlixChargingLocationInput = {
  address: JedlixAddressInput;
  chargingLocationId: Scalars['String'];
  coordinates: LatLngInput;
};

export type UpdateSmartChargingPolicyInput = {
  /**
   * Time for when the charging should be finished by.
   * hours:minutes, e.g. 08:00 or 23:59, in UTC time
   */
  deadlineInUtcTime: Scalars['String'];
  isEnabled: Scalars['Boolean'];
  vehicleId: Scalars['String'];
};

export type UpdateSmartChargingPolicyPayload = {
  smartChargingPolicy: SmartChargingPolicy;
};

export type UpdateSustainableConsumptionAlertSettingInput = {
  alertSettingId: Scalars['ID'];
  limitInWattHours: Scalars['Int'];
};

export type UpdateSustainableConsumptionAlertSettingPayload = {
  alertSetting: SustainableConsumptionAlertSetting;
};

export type User = {
  id: Scalars['String'];
  name: Scalars['String'];
  username: Scalars['ID'];
  verifiedIdentities?: Maybe<Array<Identity>>;
};

export type UserIdentifier = ExternalUserIdentifier;

export type UserIdentifierInput = {
  externalUser?: InputMaybe<ExternalUserIdentifierInput>;
};

export type UserInfo = {
  alertSettings?: Maybe<Array<AlertSetting>>;
  chargingLocations?: Maybe<Array<ChargingLocation>>;
  id: Scalars['ID'];
  intermediaries?: Maybe<Array<IntermediaryInfo>>;
  linkedIntegrationVendors?: Maybe<Array<IntegrationVendorInfo>>;
  sessions?: Maybe<Array<SessionInfoWithoutAccessToken>>;
  userIdentifier?: Maybe<UserIdentifier>;
  verifiedEmail?: Maybe<Scalars['String']>;
  verifiedIdentities?: Maybe<Array<Identity>>;
};


export type UserInfoIntermediariesArgs = {
  filter?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UserInput = {
  userIdentifier: UserIdentifierInput;
};

export type UserNotAuthenticatedError = CustomError & {
  message: Scalars['String'];
  type: ErrorType;
};

export type UserNotPermittedError = CustomError & {
  entityId?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  resourceId?: Maybe<Scalars['String']>;
  scope?: Maybe<Scalars['String']>;
  type: ErrorType;
};

export type UserPayload = {
  user: UserInfo;
};

export type Valideringsresultat = {
  status?: Maybe<Status>;
};

export type Varsling = {
  beskrivelse?: Maybe<Scalars['String']>;
  energisone?: Maybe<NordpoolArea>;
  epost?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  orgId?: Maybe<Scalars['String']>;
  varslingskriterie?: Maybe<Varslingskriterie>;
};

export type VarslingInput = {
  beskrivelse: Scalars['String'];
  energisone: NordpoolArea;
  epost: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  orgId: Scalars['String'];
  varslingskriterie: VarslingskriterieInput;
};

export type Varslingskriterie = {
  operator: Operator;
  verdi: Scalars['Float'];
};

export type VarslingskriterieInput = {
  operator: Operator;
  verdi: Scalars['Float'];
};

export type VehicleCapabilities = {
  charge: Scalars['Boolean'];
  chargeState: Scalars['Boolean'];
  desiredStateOfCharge: Scalars['Boolean'];
  geoLocation: Scalars['Boolean'];
  pullTelemetry: Scalars['Boolean'];
  startStopCharging: Scalars['Boolean'];
  stateOfCharge: Scalars['Boolean'];
};

export enum VehicleChargingStatus {
  CHARGING = 'CHARGING',
  COMPLETE = 'COMPLETE',
  DISCONNECTED = 'DISCONNECTED',
  STOPPED = 'STOPPED',
  UNKNOWN = 'UNKNOWN'
}

export type VehicleInfo = {
  batteryCapacity: Scalars['Float'];
  brand: JedlixBrand;
  model: Scalars['String'];
  range: Scalars['Float'];
  version: Scalars['String'];
};

export type VehicleSmartChargingStatusInput = {
  vehicleId: Scalars['String'];
};

export type VehicleSmartChargingStatusPayload = ConsideringSmartChargingStatus | DisabledSmartChargingStatus | FullyChargedSmartChargingStatus | PlannedSmartChargingStatus;

export enum Vendor {
  ADAX = 'ADAX',
  MILL = 'MILL'
}

/**
 * Capacity model used by Fjellnett/Eidefoss
 *
 * Computation steps, according to <https://www.fjellnett.no/privatkunder/category1153.html>:
 *
 * - Fetch consumption data for each hour for the previous twelve months.
 * - Multiply the consumption for each hour with the corresponding month weights.
 * - Find the peak value of every week.
 * - Pick the highest (5) `weekPeaksPerYear` and compute the average for these (with two decimals).
 * - Multiply by `pricePerAverageKwh`.
 * - Add `baseFeePerYear`.
 * - Divide by 12.
 */
export type WeightedYearlyRollingPeaksInDifferentWeeks = {
  baseFeePerYear: PriceSpecification;
  monthWeights: MonthWeights;
  pricePerKwhForAverageOfPeaks: PriceSpecification;
  type: CapacityModelType;
  weekPeaksPerYear: Scalars['Int'];
};

/**
 * Capacity model used by Fjellnett/Eidefoss
 *
 * Computation steps, according to <https://www.fjellnett.no/privatkunder/category1153.html>:
 *
 * - Fetch consumption data for each hour for the previous twelve months.
 * - Multiply the consumption for each hour with the corresponding month weights.
 * - Find the peak value of every week.
 * - Pick the highest (5) `weekPeaksPerYear` and compute the average for these (with two decimals).
 * - Multiply by `pricePerAverageKwh`.
 * - Add `baseFeePerYear`.
 * - Divide by 12.
 */
export type WeightedYearlyRollingPeaksInDifferentWeeksBusiness = {
  baseFeePerYear: PriceSpecification;
  monthWeights: MonthWeights;
  pricePerKwhForAverageOfPeaks: PriceSpecification;
  type: CapacityModelBusinessType;
  weekPeaksPerYear: Scalars['Int'];
  yearlyEnergyFund: PriceSpecification;
};

export type ZaptecClaimInstallationInput = {
  installationId: Scalars['String'];
};

export type ZaptecClaimInstallationPayload = {
  success: Scalars['Boolean'];
};
